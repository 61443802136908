import React, { useState } from 'react';
import { useStyles } from './SignIn.style';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Container,
  TextField,
  Typography,
  Button
} from '@material-ui/core';
import firebase from '../../utils/firebase';
import { BrowserView, MobileView } from 'react-device-detect';
import SocialLogin from '../../components/Login/SocialLogin';
import firestoreUpdateLogin from '../../utils/firestoreUpdateLogin';
import { changeLoginStatus, setUserProfile, setUserId } from '../../redux/actions';
import { setOpenDownloadAppModal } from '../../redux/entities/modals/actions';

const SignIn = () => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      let auth = await firebase
        .auth()
        .signInWithEmailAndPassword(email.trim(), password);
      let user = await firebase.auth().currentUser;
      if (auth) {
        firestoreUpdateLogin(user);
        // updateUserState(uid);
        history.push('/loading');
      }
    } catch (e) {
      // Handle Errors here.
      // var errorCode = e.code;
      // var errorMessage = e.message;
      // console.log("h", e.code);
      alert(e.message);
      alert(e.code);
      // ...
    }
  };

  // window.onbeforeunload = function() {
  //   return true;
  // };

  const handleEmailInput = e => {
    setEmail(e.target.value);
  };
  const handlePasswordInput = e => {
    setPassword(e.target.value);
  };

  const handleEnterPress = e => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Container maxWidth='sm' >
      <Typography style={{ fontWeight: 700, fontSize: '28px', marginBottom: 40, marginTop: 40, display: 'flex', justifyContent: 'center' }}>
        Log in
      </Typography>
      <TextField
        onKeyPress={handleEnterPress}
        onChange={handleEmailInput}
        value={email}
        className={classes.fillForm}
        label='Email'
        variant='outlined'
      />

      <TextField
        onKeyPress={handleEnterPress}
        onChange={handlePasswordInput}
        value={password}
        className={classes.fillForm}
        label='Password'
        variant='outlined'
        type='password'
      />
      <div style={{ borderBottom: '1px solid #E0E0E0', marginBottom: 24 }}>
        <Button
          onClick={() => handleSubmit()}
          className={classes.button}
          variant='contained'
          color='primary'
        >
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>Login</Typography>
        </Button>
      </div>

      <SocialLogin />

      <div align='center' style={{ marginTop: 40 }}>
        <MobileView>
          <Typography display='inline' >
            Don't have an account?
            &nbsp;
          </Typography>
          <Typography
            display='inline'
            onClick={() => dispatch({ type: setOpenDownloadAppModal, value: true })}
            className={classes.link}
          >
            Download Sneakerlist
          </Typography>
        </MobileView>
        <BrowserView>
          <Typography display='inline' >
            Don't have an account?
            &nbsp;
          </Typography>
          <Typography
            display='inline'
            onClick={() => history.push('/signup')}
            className={classes.link}
          >
            Sign up
          </Typography>
        </BrowserView>
      </div>
    </Container>
  );
};


const mapStateToProps = state => {
  const { loginStatus, userProfile, userGroup } = state.reducer;
  return { loginStatus, userProfile, userGroup };
};

const mapDispatchToProps = dispatch => {
  return {
    changeLogin: loginStatus => dispatch(changeLoginStatus(loginStatus)),
    setUserId: userId => dispatch(setUserId(userId)),
    setProfile: userProfile => dispatch(setUserProfile(userProfile)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
