import React, { useEffect, useState } from 'react';
import { useStyles } from './MoreGeneralProducts.style';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import ProductCard from '../../ShoeCards/ProductCard';
import getProductsWithId from '../../../utils/getProductsWithId';

/**
 * Bottom portion of General Products page that renders user listings of this particular product
 * @component
 * @param {string} sku - general product sku to fetch user listings
 */

const MoreGeneralProducts = ({sku}) => {
  const history = useHistory();
  const [moreProducts, setMoreProducts] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    fetchRelatedListings();
    //eslint-disable-next-line
  }, []);

  const fetchRelatedListings = async () => {
    const relatedListings = await getProductsWithId(sku, 18);

    setMoreProducts(relatedListings);
  };

  return (
    <div className={classes.moreProductsContainer}>
      <Typography
        style={{ fontWeight: 'bold', marginBottom: 8, }}
        variant='h2'
      >
        Listings from Sellers
      </Typography>
      {moreProducts.length === 0 ? (
        <div
          style={{height: 'auto', flexGrow: 1}}
        >No products listed</div>
      ) : (
        <Grid
          className={classes.container}
          spacing={2}
          container
          direction='row'
        >
          {
          //  eslint-disable-next-line
          moreProducts.map((product, i) => {
            const productData = product.data();
            if (productData){
              return (
                <Grid
                  xs={4}
                  md={2}
                  item
                  key={i}
                  className={classes.moreProductsCardContainer}
                  onClick={() => {
                    history.push(`/product/${product.id}`);
                    history.go(0);
                  }}
                >
                  <ProductCard hit={productData} />
                </Grid>
              );
            }
          })
          }
        </Grid>
      )}
    </div>
  );
};

export default MoreGeneralProducts;

