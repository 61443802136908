import firebase from './firebase';
import firestoreUpdateLogin from './firestoreUpdateLogin';
import { setOpenFinishSignUpModal } from '../redux/entities/modals/actions';
import { UPDATE_PENDING_SIGNUP_INFO } from '../redux/entities/signUp/actions';

const appleSignin = async (dispatch) => {
  let resp = { success: false, new: false };

  try {


    // [auth_apple_provider_create]
    let provider = new firebase.auth.OAuthProvider('apple.com');

    // [START auth_apple_provider_scopes]
    provider.addScope('email');
    provider.addScope('name');
    // [END auth_apple_provider_scopes]

    let result = await firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        console.log(result)
        return result;
      })

    if (result.additionalUserInfo.isNewUser) {
      const email = result.user.email;
      const photoURL = result.user.photoURL || null;
      const uid = result.user.uid;
      dispatch({
        type: UPDATE_PENDING_SIGNUP_INFO,
        value: {
          email: email,
          photoURL: photoURL,
          uid: uid,
        }
      });
      dispatch({ type: setOpenFinishSignUpModal, value: true });
    } else {
      resp.success = await firestoreUpdateLogin(result.user);
      resp.uid = result.user.uid;
      return resp;
    }
  } catch (e) {
    var errorMessage = e.message;
    console.error(errorMessage);
    return (resp);
  }

};

export default appleSignin;
