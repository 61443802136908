import React, { useState } from 'react';
import {
  IconButton,
  Avatar,
} from '@material-ui/core';
import UserMenu from '../UserMenu/UserMenu';

const ActionButtons = (props) => {
  const { loginStatus, userId, userProfile } = props;

  /* Menu */
  const [anchorEl, setAnchorEl] = useState(null);
  const menuId = 'primary-search-account-menu';
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <div>
        <IconButton
          // edge="end"
          // aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup='true'
          onClick={handleProfileMenuOpen}
          color='inherit'
        >
          <Avatar style={{height: 32, width: 32}} alt='Profile' src={userProfile} />
        </IconButton>
      </div>
    <UserMenu
        menuId={menuId}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        userId={userId}
        loginStatus={loginStatus}
      />
    </div>
  );
};

export default ActionButtons;