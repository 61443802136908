import firebase from './firebase';
import firestoreCreateUser from './firestoreCreateUser';
import firestoreUpdateLogin from './firestoreUpdateLogin';

const facebookSignin = async () => {
  let resp = { success: false, new: false };
  try {
    let provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({ prompt: 'consent' });
    let result = await firebase.auth().signInWithPopup(provider);
    // console.log("[facebookSignin] result: ",result);
    if (result.additionalUserInfo.isNewUser) {
      // console.log("[facebookSignin] newuser");
      const userData = {};
      userData.email = result.additionalUserInfo.profile.email
      userData.uid = result.user.uid
      userData.photoURL = result.additionalUserInfo.profile.picture.data.url
      userData.firstName = result.additionalUserInfo.profile.first_name
      userData.lastName = result.additionalUserInfo.profile.last_name
      resp.new = true;
      resp.success = await firestoreCreateUser(userData);
    } else {
      // console.log("[facebookSignin] existing user");
      resp.success = await firestoreUpdateLogin(result.user);
    }
    resp.uid = result.user.uid;
    return (resp);
  } catch (e) {
    var errorMessage = e.message;
    console.error(errorMessage);
    // var errorCode = e.code;
    // var email = e.email;
    // var credential = e.credential;
    return (resp);
  }
};

export default facebookSignin;
