import firebase from '../firebase';
import { getUserDoc } from './user';
const db = firebase.firestore();

/**
 * Gets an array of User Listings and appends buyer nickname and ID as well
 * @returns - arrray of user listing orders that are not canceled
 */
export const getOrders = async () => {
  const ordersDoc = await db
    .collection('orders')
    .where('orderStatus', '!=', 'order-canceled')
    .where('type', '==', 'USER_LISTING')
    .get()

  let orders = ordersDoc.docs;

  const orderPromises = orders.map(async el => {
    const buyerData = await getUserDoc(el.data().buyerId);
    return {...el.data(), buyer: buyerData.nickname, id: el.id};
  });

  const ordersWithBuyers = Promise.all(orderPromises);
  return ordersWithBuyers.then(a => a);
};

/**
 * Gets an array of General Listings and appends buyer nickname and ID as well
 * @returns - arrray of General Listings orders that are not canceled
 */
export const getGeneralOrders = async () => {
  const ordersDoc = await db
    .collection('orders')
    .where('orderStatus', '!=', 'order-canceled')
    .where('type', '==', 'GENERAL_PRODUCT')
    .get()

  let orders = ordersDoc.docs;

  const orderPromises = orders.map(async el => {
    const buyerData = await getUserDoc(el.data().buyerId);
    return {...el.data(), buyer: buyerData.nickname, id: el.id};
  });

  const ordersWithBuyers = Promise.all(orderPromises);
  return ordersWithBuyers.then(a => a);
};

/**
 * Gets an array of canceled orders and appends buyer nickname and ID as well
 * @returns - arrray of canceled orders
 */
export const getCanceledOrders = async () => {
  const ordersDoc = await db
    .collection('orders')
    .where('orderStatus', '==', 'order-canceled')
    .orderBy('createdAt', 'desc')
    .get()

  let orders = ordersDoc.docs;

  const orderPromises = orders.map(async el => {
    const buyerData = await getUserDoc(el.data().buyerId);
    return {...el.data(), buyer: buyerData.nickname, id: el.id};
  });

  const ordersWithBuyers = Promise.all(orderPromises);
  return ordersWithBuyers.then(a => a);
};

/**
 * finds and changes the order status on an order doc in database
 * @param {string} orderId - order doc ID
 * @param {string} updatedStatus - status for the order to be updated to
 */
export const updateOrderStatus = async (orderId, updatedStatus) => {
  await db
    .collection('orders')
    .doc(orderId)
    .update({
      orderStatus: updatedStatus,
    });
};

/**
 * grabs paypal email of user
 * @param {string} sellerId - doc ID of targeted user
 * @returns - user doc
 */
export const getSellerPaypalInfo = async (sellerId) => {
  const paypalInfo = await db
    .collection('users')
    .doc(sellerId)
    .collection('payout_info')
    .get()

  return paypalInfo.docs;
};