import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    minWidth: '350px'
  },
  container: {
    minHeight: '48px'
  },
}));
