import React from 'react';
import { useStyles } from './DownloadAppModal.style';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenDownloadAppModal } from '../../../redux/entities/modals/actions';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';
import SneakerListLogo from '../../../assets/sneakerlist_logo/sneakerlist_nobg_blk.svg';
import DownloadAppStoreButton from '../../../assets/apple_logo/apple_store_download.svg';

const DownloadAppModal = () => {
  const classes = useStyles();
  const theme = useTheme()
  const selector = state => state;
  const dispatch = useDispatch();
  const matchesXsDownBreakpoint = useMediaQuery(theme.breakpoints.down('xs'));
  const openDownloadAppModal = useSelector(selector).modals.openDownloadAppModal

  const AppStoreButton = () => {
    const handleAppStoreButtonClick = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
      if (newWindow) newWindow.opener = null;
    };

    return (
      <Button
        onClick={() => handleAppStoreButtonClick('https://apple.co/33B67Cd')}
        color='default'
        variant='text'
      >
        <img
          style={{ border: '1px solid white', borderRadius: '10px' }}
          width='100%'
          height='45'
          alt='Apple Logo'
          src={DownloadAppStoreButton}
        />
      </Button>
    );
  };


  return (
    <Dialog
      fullWidth={matchesXsDownBreakpoint ? true : false}
      className={classes.root}
      onClose={() => dispatch({ type: setOpenDownloadAppModal, value: false })}
      aria-labelledby='customized-dialog-title'
      open={openDownloadAppModal}>
      <DialogContent className={classes.contentRoot}>
        <Grid container justify='center' alignItems='center' direction='column'>
          <img
            style={{ width: '100%', objectFit: 'cover', maxHeight: '200px' }}
            src={SneakerListLogo}
            alt={'Sneakerlist logo'}
          />
          <Typography
            align='center'
            variant='h4'
            style={{ fontWeight: 600, marginBottom: 25 }}
          >
            Buy and sell authentic sneakers with others safely
          </Typography>

          <AppStoreButton />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionsRoot}>
        <Button onClick={() => dispatch({ type: setOpenDownloadAppModal, value: false })} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadAppModal;