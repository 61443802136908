import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  img: {
    padding: 0,
    opacity: 0,
    aspectRatio: 1,
    height: '100%',
  },
  loaded: {
    opacity: 1,
  },
  text:{
    padding: '0px 2px',
    position: 'absolute',
    bottom: 4,
    right: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: '80%'
    },
    backgroundColor: '#828282',
    borderRadius: '2px',
  }
}));