import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import SeeAll from '../../../components/SeeAll/SeeAll';
import { getPopularProducts, getMorePopularProducts} from '../../../utils/HomePage/getPopularProducts';


const AllGeneralProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastItem, setLastItem] = useState(true);


  const fetchProducts = async () => {
    let data = await getPopularProducts(16);

    setProducts(data.productsDocs);
    setLastItem(data.lastItem);
    setLoading(false);
  };


  useBottomScrollListener( async () => {
    let data = await getMorePopularProducts(16, lastItem);

    setProducts(products.concat(data.productsDocs));
    setLastItem(data.lastItem);
  });

  useEffect(() =>  {
    fetchProducts()
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center'}}>
      { loading ?
        <CircularProgress style={{marginTop: 80}}/> :
        <SeeAll data={products} title={'Products'}/>
      }
    </div>
  );
};

export default AllGeneralProducts;