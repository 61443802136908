import React from 'react';
import { useStyles } from './MoreUpcomingProducts.style';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
} from '@material-ui/core';

const MoreUpcomingProducts = ({upcomingData}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <Typography
          style={{ fontWeight: 'bold' }}
          variant='h2'
        >
          Other Releases
        </Typography>
      </div>
      <Grid
        spacing={2}
        container
        direction='row'
        className={classes.gridContainer}
      >
        {upcomingData.map((item, i) => {
          return (
            <Grid
              item
              key={i}
              className={classes.gridItem}
            >
              <img
                className={classes.images}
                src={item.images[0]}
                key={i}
                alt={'Upcoming Product'}
                onClick={() => {
                  history.push(`/upcoming/${item.sku}`)
                  history.go(0);
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default MoreUpcomingProducts;