import {
  CHANGE_LOGIN_STATUS,
  SET_USER_PROFILE,
  SET_USER_ID,
  SET_USER_GROUP,
} from './actionTypes';

export const changeLoginStatus = (loginStatus) => ({
  type: CHANGE_LOGIN_STATUS,
  loginStatus,
});

export const setUserProfile = (userProfile) => ({
  type: SET_USER_PROFILE,
  userProfile,
});

export const setUserId = (userId) => ({
  type: SET_USER_ID,
  userId,
});

export const setUserGroup = (userGroup) => ({
  type: SET_USER_GROUP,
  userGroup,
});
