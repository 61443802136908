import React from 'react';
import { useStyles } from './UpcomingCard.style';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import moment from 'moment';


const UpcomingCard = ({ name, imageUrl, releaseDate, itemId}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.card}>
      <img
        src={imageUrl}
        alt='user'
        onClick={() => history.push(`/upcoming/${itemId}`)}
        className={classes.cardImage}
      />
      <Typography variant='body2' style={{ color: '#333333'}}>
        {name}
      </Typography>
      <Typography variant='body2' style={{ fontWeight: 'bold', color: '#828282' }}>
        {moment(new Date(releaseDate.seconds * 1000)).format('MMMM DD')}
      </Typography>
    </div>
  );
};

export default UpcomingCard;