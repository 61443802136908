import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  noProducts: {
    justifyContent: 'center',
  },
  contentContainer: {
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      margin: '0px 16px 0px',
    },
  },
}))