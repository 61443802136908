import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 4,
      marginLeft: 4,
      width: 140,
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 6,
      marginLeft: 6,
      width: 176,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 8,
      marginLeft: 8,
      width: 224,
    },
    [theme.breakpoints.up('lg')]: {
      width: 272,
    },
    display:'flex',
    flexDirection: 'column'
  },
  cardImage: {
    [theme.breakpoints.down('sm')]: {
      width: 140,
      height: 140,
    },
    [theme.breakpoints.up('sm')]: {
      width: 176,
      height: 176,
    },
    [theme.breakpoints.up('md')]: {
      width: 224,
      height: 224,
    },
    [theme.breakpoints.up('lg')]: {
      width: 272,
      height: 272,
    },
    aspectRatio: 1,
    objectFit:'cov',
    borderRadius: 8,
    marginBottom: 8,
    cursor:'pointer',
  },
}));