import React, { useEffect, useState } from 'react';
import { useStyles } from './AdminRaffleUploader.style';
import ReactJson from 'react-json-view';
import moment from 'moment';
import { uid } from 'uid';
import firebase from '../../../utils/firebase';

import {
  TextField,
  Typography,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Checkbox,
  Chip,
  CircularProgress,
} from '@material-ui/core';

import AdminImageCropper from '../AdminImageCropper';
import { getUserDoc } from '../../../utils/firebaseFunctions/user';
import { getRaffles } from '../../../utils/firebaseFunctions/raffles';



export default function AdminRaffleUploader() {
  const classes = useStyles();
  const [post, setPost] = useState({});
  const [openCropModal, setOpenCropModal] = useState(false);
  const [rafflesDocs, setRafflesDocs] = useState([]);
  const [selectedRaffle, setSelectedRaffle] = useState(null);
  const [tempImages, setTempImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserData();
    getRafflesData();
  }, []);

  useEffect(() => {
    if (selectedRaffle) console.debug('selectedRaffle', selectedRaffle.data());
  }, [selectedRaffle]);

  const getUserData = async () => {
    const userId = sessionStorage.getItem('user');
    const userData = await getUserDoc(userId);

    const postData = {
      userId: userId,
      nickname: userData.nickname,
      profileImg: userData.profileImg,
      likesCount: 0,
      commentsCount: 0,
      deleted: false,
      type: 'upcoming',
      description: '',
      createdAt: '(created onSubmit)',
      images: '(created onSubmit)',
    };
    setPost(postData);
  };

  const getRafflesData = async () => {
    const rafflesData = await getRaffles(15);
    console.debug('rafflesData', rafflesData);
    setRafflesDocs(rafflesData);
  };

  const handleToggle = (raffle) => () => {
    if (selectedRaffle && selectedRaffle.id === raffle.id) {
      setSelectedRaffle(null);
    } else {
      setSelectedRaffle(raffle);
    }
  };

  const disableButton = () => {
    return (
      !post ||
      tempImages.length === 0 ||
      tempImages.some((img) => !img.croppedImage) ||
      Object.keys(post).some((key) => post[key] === '') ||
      (selectedRaffle && selectedRaffle.data().postId)
    );
  };

  const onChangeFileSelect = async (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const requests = Object.keys(files).map((i) => readFile(files, i));
    Promise.all(requests)
      .then((values) => {
        const tempImageArr = values.map((val) => ({ uid: uid(), image: val }));
        setTempImages(tempImageArr);
        setOpenCropModal(true);
      })
      .catch((e) => alert(`Error uploading images: ${e}`));
  };

  const handlePost = async () => {
    setLoading(true);
    try {
      let createdAt = new Date();
      const userId = sessionStorage.getItem('user');

      const imageURLs = await _uploadImages(
        tempImages.map((img) => img.croppedImage)
      );
      console.debug('imageURLs', imageURLs);
      post.images = imageURLs;
      post.createdAt = createdAt;

      // create post doc
      const upload = await firebase
        .firestore()
        .collection('posts')
        .add(post)
        .catch(function (error) {
          console.error('Error adding post doc', error);
          throw new Error(error);
        });

      if (upload) {
        console.debug('Post created successfully ', upload.id);

        // create comments
        for (const raffle of selectedRaffle.data().raffles) {
          const comment = {
            message: raffle.link,
            userId: userId,
            createdAt: new Date(Date.now()),
            nickname: post.nickname,
          };
          await firebase
            .firestore()
            .collection('posts')
            .doc(upload.id)
            .collection('comments')
            .add(comment)
            .then(() => {
              console.debug('Comment added');
            })
            .catch(function (error) {
              console.warn('Error updating comments', error);
            });

          await firebase
            .firestore()
            .collection('posts')
            .doc(upload.id)
            .update({
              commentsCount: firebase.firestore.FieldValue.increment(1),
            });
        }

        // create user->post doc
        await firebase
          .firestore()
          .collection('users')
          .doc(userId)
          .collection('posts')
          .doc(upload.id)
          .set({
            postId: upload.id,
            currentTime: createdAt,
            deleted: false,
          })
          .then(() => {
            console.debug('User data updated successfully');
          })
          .catch((error) => {
            console.error('Error updating user doc', error);
            throw new Error(error);
          });

        // update raffles doc
        await firebase
          .firestore()
          .collection('raffles')
          .doc(selectedRaffle.id)
          .update({
            postId: upload.id,
          })
          .then(() => {
            console.debug('Raffle data updated successfully');
          })
          .catch((error) => {
            console.error('Error updating raffle data', error);
            throw new Error(error);
          });

        alert('Post upload success: ', upload.id);
        getUserData(); // reset
        window.location.reload();
      }
    } catch (e) {
      console.debug('Post upload failed: ', e);
      alert('Post upload failed (see debug logs) ');
      window.location.reload();
    }
  };

  const disableLinksUpdateButton = () => {
    return !selectedRaffle || (selectedRaffle && !selectedRaffle.data().postId);
  };

  const handleRaffleLinksUpdate = async () => {
    setLoading(true);
    const userId = sessionStorage.getItem('user');
    const postId = selectedRaffle.data().postId;
    const commentsDocs = await firebase
      .firestore()
      .collection('posts')
      .doc(postId)
      .collection('comments')
      .get();

    console.debug('commentsDocs', commentsDocs);

    const comments = commentsDocs.docs.map((doc) => doc.data().message);
    console.debug('comments', comments);

    const links = selectedRaffle.data().raffles.map((raffle) => raffle.link);
    console.debug('links', links);

    const newLinks = links.filter((link) => !comments.includes(link));
    console.debug('newLinks', newLinks);
    if (newLinks.length > 0) {
      for (const newLink of newLinks) {
        const comment = {
          message: newLink,
          userId: userId,
          createdAt: new Date(Date.now()),
          nickname: post.nickname,
        };
        await firebase
          .firestore()
          .collection('posts')
          .doc(postId)
          .collection('comments')
          .add(comment)
          .then(() => {
            console.debug('Comment added');
          })
          .catch(function (error) {
            console.warn('Error updating comments', error);
          });
        await firebase
          .firestore()
          .collection('posts')
          .doc(postId)
          .update({
            commentsCount: firebase.firestore.FieldValue.increment(1),
          });
      }
      alert(`${newLinks.length} links have been added`);
      window.location.reload();
    } else {
      alert('No new raffle links have been detected!');
      window.location.reload();
    }
    // reset
    getUserData();
    setSelectedRaffle(null);
  };

  return (
    <div className={classes.container}>
      {!loading ? (
        <>
          <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
            Upload a raffle post
          </Typography>
          <List
            dense
            style={{ width: '100%', overflow: 'auto', maxHeight: 500 }}
          >
            {rafflesDocs.map((raffle) => (
              <ListItem key={raffle.id}>
                <ListItemAvatar>
                  <Avatar alt='post_image' src={raffle.data().image} />
                </ListItemAvatar>
                <ListItemText
                  primary={raffle.data().name}
                  secondary={`${moment
                    .unix(raffle.data().createdTime.seconds)
                    .fromNow()} / raffleId: ${raffle.id} / postId: ${
                    raffle.data().postId ? raffle.data().postId : 'none'
                  }`}
                />
                <ListItemSecondaryAction>
                  {raffle.data().postId ? (
                    <Chip label='Post exists' variant='outlined' size='small' />
                  ) : null}
                  <Checkbox
                    edge='end'
                    onChange={handleToggle(raffle)}
                    checked={selectedRaffle && selectedRaffle.id === raffle.id}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {selectedRaffle && !selectedRaffle.data().postId ? (
            <div className={classes.input}>
              <input type='file' multiple onChange={onChangeFileSelect} />
              <div
                style={{
                  flex: 1,
                  height: 75,
                  flexDirection: 'row',
                  overflowX: 'auto',
                  marginTop: 8,
                }}
              >
                {tempImages.every((img) => img.croppedImage) &&
                  tempImages.map((image) => (
                    <img
                      key={image.uid}
                      alt={image.uid}
                      src={image.croppedImage}
                      style={{ height: 60, width: 'auto', marginRight: 8 }}
                    />
                  ))}
              </div>
            </div>
          ) : null}

          <TextField
            multiline
            rows={3}
            className={classes.input}
            size='small'
            id='outlined-body'
            label='Description'
            value={post.description}
            onChange={(event) =>
              setPost({ ...post, description: event.target.value })
            }
            variant='outlined'
          />

          <div style={{ flexGrow: 1, marginTop: 16 }}>
            <Typography style={{ fontSize: 14 }}>
              Review post document:
            </Typography>
            <ReactJson src={post} />
          </div>
          <Button
            variant='contained'
            color='primary'
            size='small'
            style={{ marginTop: 8 }}
            disabled={!tempImages || disableButton()}
            onClick={() => handlePost()}
          >
            Upload Raffle Post
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='small'
            style={{ marginTop: 8 }}
            disabled={disableLinksUpdateButton()}
            onClick={() => handleRaffleLinksUpdate()}
          >
            Update comment links
          </Button>

          {selectedRaffle ? (
            <AdminImageCropper
              open={openCropModal}
              setOpen={setOpenCropModal}
              images={tempImages}
              setImages={setTempImages}
            />
          ) : null}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

// helper util functions:

const readFile = (files, i) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(files[i]);
    } catch (e) {
      reject(e);
    }
  });
};

const _uploadImages = async (images) => {
  const uploadURLs = await Promise.all(
    images.map(async (item) => await _uploadImage(item))
  );
  return uploadURLs;
};

const _uploadImage = async (imageURI) => {
  const response = await fetch(imageURI);
  const blob = await response.blob();
  const ext = blob.type.split('/')[1];
  const filename = `${uid()}.${ext}`;
  const imgRef = firebase.storage().ref(`product_images/${filename}`);
  await imgRef.put(blob);
  return imgRef.getDownloadURL();
};
