import firebase from './firebase';
import firestoreCreateUser from './firestoreCreateUser';

//Function to update lastLoggedIn attribute for users with firestore documents.

const firebaseUpdateLogin = async user => {
  try {
    await firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .collection('private')
      .doc('doc')
      .update({ lastLoggedIn: new Date(Date.now()) });

    return (true);
  } catch (e) {
    //If a user has auth but no firestore doc, a new doc is created.

    if (e.code === 'not-found') {
      let created = await firestoreCreateUser(user);
      if (created) {
        return (true);
      } else {
        // console.error("user not found and cannot be created");
        return (false);
      }
    }
    // console.error(e);
  }
};

export default firebaseUpdateLogin;
