import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(5),
    // [theme.breakpoints.down("sm")]: {
    //   minHeight: `calc(100vh - ${theme.appbarHeight.smDown}px - 48px)`,
    //   height: `calc(100vh - ${theme.appbarHeight.smDown}px - 48px)`,
    // },
    // [theme.breakpoints.up("md")]: {
    //   minHeight: `calc(100vh - ${theme.appbarHeight.mdUp}px - 48px)`,
    //   height: `calc(100vh - ${theme.appbarHeight.mdUp}px - 48px)`,
    // },
  },
  input: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  reviewText: {
    fontSize: 13,
    margin: '2px 0',
  },
}));