import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import SeeAll from '../../../components/SeeAll/SeeAll';
import getUpcomingProducts from '../../../utils/HomePage/getUpcomingProducts';


const AllUpcomingProducts = () => {
  const [upcomingData, setUpcomingData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUpcomingOrders = async () => {
    let data = await getUpcomingProducts(50);
    setUpcomingData(data);
    setLoading(false);
  };

  useEffect(() =>  {
    fetchUpcomingOrders()
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center'}}>
      { loading ?
        <CircularProgress style={{marginTop: 80}}/> :
        <SeeAll data={upcomingData} title={'Upcoming Drops'}/>
      }
    </div>
  );
};

export default AllUpcomingProducts;