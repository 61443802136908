import React, { useEffect, useState } from 'react';
import { useStyles } from './Product.style';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
} from '@material-ui/core';
import fetchProductDetails from '../../utils/fetchProductDetails';
import ProductInfo from '../../components/Product/ProductInfo';
import MoreProducts from '../../components/Product/MoreProducts';


const Product = () => {
  const { productID } = useParams();
  const [product, setProduct] = useState({});
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      let productData = await fetchProductDetails(productID);
      if (productData) {
        setProduct(productData);
      }
    })();
  }, []);

  if (
    Object.keys(product).length === 0
  ) {
    return (
      <section
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </section>
    );
  }

  return (
    <div className={classes.container} >
      <div className={classes.subContainer} >
        <ProductInfo product={product} />

        <MoreProducts
          productData={{ ...product, currentProductID: productID }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { userId, loginStatus } = state.reducer;
  return { userId, loginStatus };
};
export default connect(mapStateToProps)(Product);
