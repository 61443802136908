import firebase from './firebase';

const firebaseSignout = async () => {
  return async (resolve, reject) => {
    try {
      firebase.auth().signOut();
      resolve(true);
    } catch (e) {
      console.error(e);
      reject(false);
    }
  };
};

export default firebaseSignout;
