import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 auto',
    minHeight: '100vh',
    marginBottom: 16,
    [theme.breakpoints.down('md')]: {
      width: 486,
    },
    [theme.breakpoints.up('md')]: {
      width: 624,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    backgroundColor: 'white',
  },
  notExist: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,
  },
  loading: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));