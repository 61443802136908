import React, { useState } from 'react';
import { useStyles } from './GeneralProductInfo.style';
import {
  Typography,
  Button,
  Tooltip,
} from '@material-ui/core';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

/**
 * Renders top part of General Product page including image and information
 * @param {object} product - object of product to be displayed
 */

const GeneralProductInfo = ({product}) => {
  const [loaded, setLoaded] = useState(false);
  const [liked, setliked] = useState(false);
  const classes = useStyles();

  const {
    main_picture_url: image,
    name,
    release_date: releaseDate,
    brand_name: brand,
  } = product;


  const handleLikeClick = () => {
    // add functionality to like post
    setliked(!liked);
  };

  const handleMessageClick = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className={classes.productContainer}>

      {/** PRODUCT IMAGES */}
      <div className={classes.imageContainer}>
        <img
          src={image}
          onLoad={() => setLoaded(true)}
          alt='sneaker'
          className={`${classes.image} ${loaded ? classes.imgLoaded : ''}`}
        />
      </div>

      {/** PRODUCT && SELLER DETAILS */}
      <div className={classes.informationContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Typography variant='h2' style={{ marginTop: 3}}>
            {name}
          </Typography>

          {liked
          ? <FavoriteIcon
            className={classes.filledlikeButton}
            onClick={()=> handleLikeClick()}
          />
          : <FavoriteBorderIcon
            className={classes.likeButton}
            onClick={()=> handleLikeClick()}
          />

          }

        </div>

        <div style={{marginTop: 10}}>
          <Typography display='inline' variant='body2' style={{color: '#828282' }}>
            Brand:&nbsp;
          </Typography>
          <Typography display='inline' variant='body2' >
            {brand}
          </Typography>
        </div>

        {releaseDate &&
          <div style={{marginTop: 10}}>
            <Typography display='inline' variant='body2' style={{color: '#828282'}}>
              Release Date:&nbsp;
            </Typography>
            <Typography display='inline' variant='body2' >
               {releaseDate.slice(0,10)}
            </Typography>
          </div>
        }
        <Typography variant='body2' style={{color: '#828282', marginTop: 10}}>
          Full Authentication Guarantee
        </Typography>
        <div style={{display: 'flex'}}>
          <Typography variant='body2' style={{color: '#828282', marginTop: 10,}}>
            Lowest Price Guarantee&nbsp;
          </Typography>
          <Tooltip title={
            <Typography variant='subtitle1'>
              If within 7 days after your purchase, you find the same item sold
              at a lower price (including fees) at any of the major sneakers platforms,
              we will provide you store credit to match up the difference.
              Guarantee does not cover products sold by individual resellers.
            </Typography>
          }>
            <InfoOutlinedIcon display='inline' style={{fontSize: 16, alignSelf: 'flex-end', marginBottom: 2,  }}/>
          </Tooltip>
        </div>

        <Button
          variant='contained'
          className={classes.button}
          onClick={() => handleMessageClick('https://apps.apple.com/us/app/sneakerlist-buy-sell-shoes/id1536068234')}
        >
          <Typography style={{fontWeight: 'bold'}} variant='body1'>
            Purchase on the Sneakerlist app
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export default GeneralProductInfo;

