import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Avatar,
  Typography,
  Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { follow, unfollow, checkFollowing } from '../../../utils/firebaseFunctions/profile'
import Rating from '@material-ui/lab/Rating';
import { setOpenDownloadAppModal } from '../../../redux/entities/modals/actions';
// import { createClassNames } from "react-instantsearch-dom";

const ProfileHeader = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory({ forceRefresh: true });
  const { loginStatus, profileUserData, isSignedInUser, userId } = props
  const [following, setFollowing] = useState(false);


  const checkFollowingProfile = async () => {
    const result = await checkFollowing(userId, profileUserData.userId)
    if (result) {
      setFollowing(true)
    }
  };

  const handleFollow = async () => {
    try {
      const result = await follow(userId, profileUserData.userId)
      if (result) setFollowing(true)
    } catch (e) {
      console.error('Failed following user')
    }
  };

  const handleUnfollow = async () => {
    try {
      const result = await unfollow(userId, profileUserData.userId)
      if (result) setFollowing(false)
    } catch (e) {
      console.error('Failed following user')
    }
  };

  useEffect(() => {
    // console.log("[Profile] profileUserData: ", profileUserData)
    // console.log("[Profile] userId: ", userId)
    if (loginStatus && !isSignedInUser) checkFollowingProfile();
  });

  return (
    <div className={classes.container}>
      {/* top container */}
      <div className={classes.topContainer}>
        <div className={classes.avatar}>
          <Avatar className={classes.profilePic} alt={profileUserData.nickname} src={profileUserData.profileImg} />
        </div>
        <div>
          <Typography style={{ marginTop: '4px', marginBottom: 8, fontWeight:'bold' }} variant='h2'>
            {profileUserData.nickname}
          </Typography>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Rating
              style={{ color: '#F2C94C', marginRight: 8}}
              name='simple-controlled'
              value={profileUserData.averagerating}
              readOnly
            />
            <Typography variant={'subtitle1'} style={{ color: '#828282' }}>{` (${profileUserData.reviewcount})`}</Typography>
          </div>
        </div>
      </div>
      {/* bottom container*/}
      <div className={classes.subContainer}>
        <div>
          {!loginStatus
            ? <Button
              color='primary'
              variant='outlined'
              className={classes.button}
              onClick={() => dispatch({type: setOpenDownloadAppModal, value: true})}
            >
              Follow
            </Button>
            : !isSignedInUser && !following
              ? <Button
                color='primary'
                variant='contained'
                style={classes.button}
                onClick={() => handleFollow()}
              >
                Follow
              </Button>
              : !isSignedInUser && following
                ? <Button
                  color='primary'
                  variant='contained'
                  style={classes.button}
                  onClick={() => handleUnfollow()}
                >
                  Following
                </Button>
                : <Button
                  variant='outlined'
                  style={classes.button}
                  onClick={() => history.push('/account/edit')}
                >
                  Edit Profile
                </Button>
            }
        </div>

        <div className={classes.counts}>
          <Button className={classes.countsItem}>
            <div>
              <Typography variant='body1' style={{fontWeight: 'bold', color: '#333333'}}>{profileUserData.followerscount}</Typography>
              <Typography variant='body2' style={{color: '#333333'}}>Followers</Typography>
            </div>
          </Button>
          <Button className={classes.countsItem}>
            <div>
              <Typography variant='body1' style={{fontWeight: 'bold', color: '#333333'}}>{profileUserData.followingcount}</Typography>
              <Typography variant='body2' style={{color: '#333333'}}>Following</Typography>
            </div>
          </Button>
        </div>

      </div>

      <Grid item style={{ marginTop: '16px', width: '100%' }}>
        <Typography variant='body1'>{profileUserData.about}</Typography>
      </Grid>
    </div>
  );
};

export default ProfileHeader;

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      margin: '16px 16px 0px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '40px',
    },
  },
  subContainer: {
    width: '100%',
    marginTop: 18,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    [theme.breakpoints.down('md')]: {
      marginRight: 49,
      marginLeft: 31,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 77,
      marginLeft: 53,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 16,
      marginLeft: 0,
    },
  },
  profilePic: {
    [theme.breakpoints.down('sm')]: {
      width: '96px',
      height: '96px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '109px',
      height: '109px',
    },
  },
  userInfo: {
    marginTop: 8,
  },
  button: {
    height: 32,
    [theme.breakpoints.down('md')]: {
      width: 171,
    },
    [theme.breakpoints.up('md')]: {
      width: 223,
    },
    [theme.breakpoints.down('sm')]: {
      width: 104,
      marginRight: 30,
    },
    textTransform: 'none',
  },
  counts: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      columnGap: 16,
    },
    [theme.breakpoints.up('sm')]: {
      columnGap: 40,
    },

  },
  countsItem: {
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    color: '#333333',
  }
}));