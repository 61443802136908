import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  MenuItem,
  Menu,
  Divider,
  Typography,
} from '@material-ui/core';
import { changeLoginStatus, setUserProfile, setUserId, setUserGroup } from '../../../redux/actions';
import firebaseSignout from '../../../utils/firebaseSignout';
import firebaseGetUserProfile from '../../../utils/firebaseGetUserProfile';

const UserMenu = (props) => {

  const history = useHistory({ forceRefresh: true });
  const { menuId, anchorEl, setAnchorEl, userId } = props;
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const goToProfile = async () => {
    const userDataTemp = await firebaseGetUserProfile(userId);
    handleMenuClose();
    history.push(`/profile/${userDataTemp.nickname}`);
    window.location.reload(true); //force refresh
  };

  const handleSignout = async () => {
    let signedout = await firebaseSignout();
    if (signedout) {
      sessionStorage.setItem('isLoggedIn', false);
      sessionStorage.setItem('userProfile', '');
      sessionStorage.setItem('userGroup', '');
      sessionStorage.clear();
      // changeLogin(false);
      // setProfile("");
      setUserId('')
      setUserGroup('default')
    }
    window.location.reload(true); //force refresh
  };

  return (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={goToProfile}>
        <Typography variant='body2'>Profile</Typography>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Typography variant='body2'>Settings</Typography>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleSignout}>
        <Typography variant='body2'>Log Out</Typography>
      </MenuItem>
    </Menu>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    changeLogin: loginStatus => dispatch(changeLoginStatus(loginStatus)),
    setProfile: userProfile => dispatch(setUserProfile(userProfile)),
    setUserId: userId => dispatch(setUserId(userId)),
    setUserGroup: userGroup => dispatch(setUserId(userGroup))
  };
};

export default connect(null, mapDispatchToProps)(UserMenu);
