import firebase from '../firebase';

const getFeaturedUsers = async (limit) => {
  try {
    const featuredUsers = await firebase
      .firestore()
      .collection('users')
      .orderBy('lastUpload', 'desc')
      .where('star', '==', true)
      .limit(limit)
      .get();

    const featuredUsersDocs = featuredUsers.docs.map(
      el => el.data()
    );

    return featuredUsersDocs;
  } catch(e) {
    console.error(e);
  }
};

export default getFeaturedUsers;