import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  containerFeed: {
    minHeight: '100vh',
    padding: 0,
  },
  container: {
    padding: 0,
    paddingTop: theme.spacing(6),
    width: '100%'
  }
}));