import React, { useState } from 'react';
import { useStyles } from './MobileMenu.style';
import { useHistory } from 'react-router-dom';
import {
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const MobileMenu = () => {
  const history = useHistory({ forceRefresh: true });
  // const {setHomeTab} = props;

  /* Menu */
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'mobile-menu';
  const classes = useStyles();
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigate = (page) => {
    if (page === 'login') {
      history.push('/signin')
    } else {
      history.push('/signup')
    }
    handleMenuClose()
  }
  return (
    <div className={classes.container}>
      <Hidden mdUp>
        <IconButton
          style={{ color: 'black', padding: 8 }}
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => navigate('login')} >
            <Typography variant='body2'>Login</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate('signup')}>
            <Typography variant='body2'>Sign up</Typography>
          </MenuItem>
        </Menu>
      </Hidden>
    </div>
  );
};

export default MobileMenu;

