import React, { useEffect, useState } from 'react';
import { useStyles } from './AdminNotification.style';
import Datetime from 'react-datetime';
import {
  Typography,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogActions,
  Collapse,
} from '@material-ui/core';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import firebase from '../../../utils/firebase';
import { getAdminPosts } from '../../../utils/firebaseFunctions/post';

export default function AdminNotification() {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [type, setType] = useState('');
  const [postId, setPostId] = useState('');
  const [postDescription, setPostDescription] = useState('');
  const [recentPosts, setRecentPosts] = useState([]);
  const [openPostsList, setOpenPostsList] = useState(true);

  // states fir scheduler modal
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(null);
  const [openScheduledNotifications, setOpenScheduledNotifications] =
    useState(false);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    const posts = await getAdminPosts(20, 't46M1RlL73d2pmcTlTv3sSQTanI3');
    // const posts = await getPosts(20);
    setRecentPosts(posts);
  };

  const handleToggle = (id, description) => () => {
    if (id === postId) {
      setPostId('');
      setPostDescription('');
      setOpenPostsList(true);
    } else {
      setPostId(id);
      setPostDescription(description);
      setOpenPostsList(false);
    }
  };

  const handleClickExpandCollapse = () => {
    setOpenPostsList(!openPostsList);
  };

  const sendNotification = () => {
    const notification = {
      body: body,
      createdTime: new Date(Date.now()),
      type: type,
      title: title,
      userId: 't46M1RlL73d2pmcTlTv3sSQTanI3',
      sound: 'default',
      data: {
        screen: 'MyPosts',
        params: { postId: postId, userId: 't46M1RlL73d2pmcTlTv3sSQTanI3' },
      },
    };
    console.debug('notification: ', notification);

    firebase
      .firestore()
      .collection('notifications')
      .add(notification)
      .then((docRef) => {
        console.debug('Notification generated with docRed: ', docRef.id);
        alert(`Notification generated with docRed: ${docRef.id}`);
        window.location.reload();
      })
      .catch((error) => {
        console.debug('error', error);
        alert('Error generating notification: ', error);
      });
  };

  // const scheduleNotification = async () => {
  //   const notification = {
  //     unixTimeInSecs: time,
  //     message: body,
  //     postId,
  //     title,
  //     type,
  //   };
  //   // console.debug('scheduleNotification', notification)
  //   firebase
  //     .firestore()
  //     .collection("scheduledNotifications")
  //     .add(notification)
  //     .then((docRef) => {
  //       console.debug("Notification scheduled with docRef: ", docRef.id);
  //       alert(`Notification scheduled with docRef: ${docRef.id}`);
  //     })
  //     .catch((error) => {
  //       console.debug("error", error);
  //       alert("Error scheduling notification: ", error);
  //     });
  // };

  const disableButton = () => {
    return title === '' || body === '' || type === '' || postId === '';
  };

  // const disableButton2 = () => {
  //   return title === "" || body === "" || type === "" || postId === "" || !time;
  // };

  const handleOnChangeDateTime = (obj) => {
    console.debug(time);
    setTime(obj.unix());
  };

  return (
    <div className={classes.container}>
      <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
        Push notification
      </Typography>
      <TextField
        className={classes.input}
        size='small'
        id='outlined-title'
        label='Title'
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        variant='outlined'
      />
      <FormControl className={classes.input} variant='outlined' size='small'>
        <InputLabel>Type</InputLabel>
        <Select
          value={type}
          onChange={(event) => setType(event.target.value)}
          variant='outlined'
          size='small'
          labelWidth={40}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          <MenuItem value={'raffles'}>raffles</MenuItem>
          <MenuItem value={'drops'}>drops</MenuItem>
          <MenuItem value={'upcoming'}>upcoming</MenuItem>
        </Select>
      </FormControl>
      <TextField
        className={classes.input}
        size='small'
        id='outlined-body'
        label='Body'
        value={body}
        onChange={(event) => setBody(event.target.value)}
        variant='outlined'
      />

      <List component='nav' aria-labelledby='nested-list-subheader'>
        <ListItem button onClick={handleClickExpandCollapse}>
          <ListItemText
            primary={
              <Typography style={{ fontSize: 14 }}>Select a post</Typography>
            }
          />
          {openPostsList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openPostsList} timeout='auto' unmountOnExit>
          <List dense style={{ width: '100%', overflow: 'auto' }}>
            {recentPosts
              .filter((post) => post.id !== postId)
              .map((post) => (
                <ListItem
                  key={post.id}
                  button
                  onClick={handleToggle(post.id, post.data().description)}
                >
                  <ListItemAvatar>
                    <Avatar alt='post_image' src={post.data().images[0]} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={post.data().description}
                    secondary={`${moment
                      .unix(post.data().createdAt.seconds)
                      .fromNow()} / postId: ${post.id}`}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge='end'
                      onChange={handleToggle(post.id, post.data().description)}
                      checked={postId === post.id}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </Collapse>
        {recentPosts
          .filter((post) => post.id === postId)
          .map((post) => (
            <ListItem
              key={post.id}
              button
              onClick={handleToggle(post.id, post.data().description)}
            >
              <ListItemAvatar>
                <Avatar alt='post_image' src={post.data().images[0]} />
              </ListItemAvatar>
              <ListItemText
                primary={post.data().description}
                secondary={`${moment
                  .unix(post.data().createdAt.seconds)
                  .fromNow()} / postId: ${post.id}`}
              />
              <ListItemSecondaryAction>
                <Checkbox
                  edge='end'
                  onChange={handleToggle(post.id, post.data().description)}
                  checked={postId === post.id}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>

      <Typography style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 8 }}>
        Review:
      </Typography>
      <Typography className={classes.reviewText}>
        <span style={{ fontWeight: 'bold' }}>Title: </span>
        {title}
      </Typography>
      <Typography className={classes.reviewText}>
        <span style={{ fontWeight: 'bold' }}>Type: </span>
        {type}
      </Typography>
      <Typography className={classes.reviewText}>
        <span style={{ fontWeight: 'bold' }}>Body: </span>
        {body}
      </Typography>
      <Typography className={classes.reviewText}>
        <span style={{ fontWeight: 'bold' }}>Post: </span>
        {postDescription}
      </Typography>
      <Typography className={classes.reviewText}>
        <span style={{ fontWeight: 'bold' }}>PostId: </span>
        {postId}
      </Typography>
      <div
        style={{
          display: 'inline-flex',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          onClick={() => sendNotification()}
          variant='contained'
          color='primary'
          size='small'
          style={{ marginTop: 8 }}
          disabled={disableButton()}
        >
          Send Notification
        </Button>
      </div>
      {/* <div
        style={{
          display: "inline-flex",
          width: "100%",
          // justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => setOpenScheduledNotifications(true)}
        >
          Open Scheduled Notifications
        </Button>
        <div style={{ flexGrow: 1 }} />
        {time ? new Date(time * 1000).toLocaleString() : null}
        <Button
          onClick={() => setOpen(true)}
          variant="outlined"
          color="primary"
          size="small"
          style={{ marginTop: 8, marginRight: 8 }}
          disabled={disableButton()}
        >
          Set Time
        </Button>
        <Button
          onClick={() => scheduleNotification()}
          variant="contained"
          color="primary"
          size="small"
          style={{ marginTop: 8 }}
          disabled={disableButton2()}
        >
          Schedule Notification
        </Button>
      </div> */}
      <TimeDialog
        open={open}
        handleOnChangeDateTime={handleOnChangeDateTime}
        setOpen={setOpen}
      />
      <ScheduledNotifications
        open={openScheduledNotifications}
        setOpen={setOpenScheduledNotifications}
      />
    </div>
  );
}

function TimeDialog(props) {
  const { setOpen, open, handleOnChangeDateTime } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <DialogTitle id='simple-dialog-title'>Set date and time</DialogTitle>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Datetime onChange={handleOnChangeDateTime} />
      </div>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ScheduledNotifications(props) {
  const { setOpen, open } = props;
  const [notificationDocs, setNotificationDocs] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      onLoad();
    }
    // eslint-disable-next-line
  }, [open]);

  const onLoad = async () => {
    try {
      const docs = await getScheduledNotifications();
      docs.forEach((doc) => console.debug(doc.data()));
      setNotificationDocs(docs);
    } catch (e) {
      console.debug('Error fetching scheduled notifications: ', e);
    }
  };

  const getScheduledNotifications = async () => {
    try {
      const postDocQuery = await firebase
        .firestore()
        .collection('scheduledNotifications')
        .orderBy('unixTimeInSecs', 'desc')
        .get();

      const postsDocs = postDocQuery.docs;
      return (postsDocs);
    } catch (e) {
      return (e);
    }
  };

  const handleDeleteScheduledNotification = async (doc) => {
    console.debug('delete requested for ', doc.id);
    try {
      await firebase
        .firestore()
        .collection('scheduledNotifications')
        .doc(doc.id)
        .update({ deleting: true });
      setNotificationDocs(
        notificationDocs.filter((document) => document.id !== doc.id)
      );
      alert('Scheduled notification has been successfully deleted!');
    } catch (e) {
      alert('Failed deleting notification');
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      maxWidth='lg'
    >
      <DialogTitle id='simple-dialog-title'>
        Scheduled Notifications
      </DialogTitle>
      {notificationDocs.length === 0 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          None
        </div>
      ) : null}
      <List dense style={{ width: '100%', maxWidth: 800 }}>
        {notificationDocs.map((doc) => {
          const docId = doc.id;
          const notification = doc.data();
          const dateTime = new Date(
            notification.unixTimeInSecs * 1000
          ).toLocaleString();
          return (
            <ListItem key={docId}>
              <ListItemText
                component='div'
                primary={`${notification.title} (type: ${notification.type}) `}
                secondary={
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{notification.message}</span>
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${dateTime} / postId: ${notification.postId}`}</span>
                  </span>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => handleDeleteScheduledNotification(doc)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <ListItem></ListItem>
      </List>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
