import React from 'react';
import { useStyles } from './DownloadBanner.style';
import {
  Button,
  Typography
} from '@material-ui/core';


const DownloadBanner = () => {
  const classes = useStyles();

  const handleMessageClick = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant='body1' style={{color: 'white', fontWeight: 'bold '}}>
          Buy and sell authentic sneakers with no fees
        </Typography>

        <Button
          className={classes.button}
          onClick={() => handleMessageClick('https://apps.apple.com/us/app/sneakerlist-buy-sell-shoes/id1536068234')}
        >
          <Typography variant='body2' style={{fontWeight: 'bold'}}> Download the app </Typography>
        </Button>
      </div>
    </div>
  )
};

export default DownloadBanner;
