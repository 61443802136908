export const sizeData = [
  { label: 'M 3.5 / W 5.0', value: 'M 3.5 / W 5.0' },
  { label: 'M 4.0 / W 5.5', value: 'M 4.0 / W 5.5' },
  { label: 'M 4.5 / W 6.0', value: 'M 4.5 / W 6.0' },
  { label: 'M 5.0 / W 6.5', value: 'M 5.0 / W 6.5' },
  { label: 'M 5.5 / W 7.0', value: 'M 5.5 / W 7.0' },
  { label: 'M 6.0 / W 7.5', value: 'M 6.0 / W 7.5' },
  { label: 'M 6.5 / W 8.0', value: 'M 6.5 / W 8.0' },
  { label: 'M 7.0 / W 8.5', value: 'M 7.0 / W 8.5' },
  { label: 'M 7.5 / W 9.0', value: 'M 7.5 / W 9.0' },
  { label: 'M 8.0 / W 9.5', value: 'M 8.0 / W 9.5' },
  { label: 'M 8.5 / W 10.0', value: 'M 8.5 / W 10.0' },
  { label: 'M 9.0 / W 10.5', value: 'M 9.0 / W 10.5' },
  { label: 'M 9.5 / W 11.0', value: 'M 9.5 / W 11.0' },
  { label: 'M 10.0 / W 11.5', value: 'M 10.0 / W 11.5' },
  { label: 'M 10.5 / W 12.0', value: 'M 10.5 / W 12.0' },
  { label: 'M 11.0 / W 12.5', value: 'M 11.0 / W 12.5' },
  { label: 'M 11.5 / W 13.0', value: 'M 11.5 / W 13.0' },
  { label: 'M 12.0 / W 13.5', value: 'M 12.0 / W 13.5' },
  { label: 'M 12.5 / W 14.0', value: 'M 12.5 / W 14.0' },
  { label: 'M 13.0 / W 14.5', value: 'M 13.0 / W 14.5' },
  { label: 'M 13.5 / W 15.0', value: 'M 13.5 / W 15.0' },
  { label: 'M 14.0 / W 15.5', value: 'M 14.0 / W 15.5' },
  { label: 'M 14.5 / W 16.0', value: 'M 14.5 / W 16.0' },
  { label: 'M 15.0 / W 16.5', value: 'M 15.0 / W 16.5' },
  { label: 'M 15.5 / W 17.0', value: 'M 15.5 / W 17.0' },
  { label: 'M 16.0 / W 17.5', value: 'M 16.0 / W 17.5' },
  { label: 'M 16.5 / W 18.0', value: 'M 16.5 / W 18.0' },
  { label: 'Y 2.0', value: 'Y 2.0' },
  { label: 'Y 2.5', value: 'Y 2.5' },
  { label: 'Y 3.0', value: 'Y 3.0' },
  { label: 'Y 3.5', value: 'Y 3.5' },
  { label: 'Y 4.0', value: 'Y 4.0' },
  { label: 'Y 4.5', value: 'Y 4.5' },
  { label: 'Y 5.0', value: 'Y 5.0' },
  { label: 'Y 5.5', value: 'Y 5.5' },
  { label: 'Y 6.0', value: 'Y 6.0' },
  { label: 'Y 6.5', value: 'Y 6.5' },
  { label: 'Y 7.0', value: 'Y 7.0' },
  { label: 'Y 7.5', value: 'Y 7.5' },
];

export const conditionData = [
  { label: 'New with box', value: 'New with box' },
  { label: 'New with defects', value: 'New with defects' },
  { label: 'Used in good condition', value: 'Used in good condition' },
];
