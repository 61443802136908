import React, { useState, useEffect } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';

export default function AdminImageCropper(props) {
  const { open, setOpen, images, setImages } = props;
  const [selectedImage, setSelectedImage] = useState(null);
  const [cropper, setCropper] = useState();

  useEffect(() => {
    if (open) {
      setSelectedImage(images[0]);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      const i = images.findIndex((img) => img.uid === selectedImage.uid);
      const imagesCopy = [...images];
      imagesCopy[i].croppedImage = cropper.getCroppedCanvas().toDataURL();
      setImages(imagesCopy);
      if (i + 1 < images.length) {
        setSelectedImage(images[i + 1]);
      } else {
        handleClose();
      }
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      fullScreen={true}
      open={open && images.length > 0}
    >
      <DialogTitle>Crop your uploaded images</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        {selectedImage &&
          images.map((image) => (
            <img
              key={image.uid}
              alt={image.uid}
              src={image.croppedImage ? image.croppedImage : image.image}
              style={{
                height: 60,
                width: 'auto',
                marginRight: 8,
                cursor: image.croppedImage ? 'default' : 'pointer',
                border:
                  image.uid === selectedImage.uid ? '5px solid yellow' : null,
                backgroundColor: image.croppedImage
                  ? 'rgba(250,250,50,0.8)'
                  : null,
              }}
            />
          ))}
        {selectedImage ? (
          <Cropper
            style={{ height: '100%', width: '100%' }}
            zoomTo={2}
            initialAspectRatio={1}
            preview='.img-preview'
            src={selectedImage.image}
            viewMode={0}
            guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            responsive={true}
            aspectRatio={1 / 1}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        ) : (
          <div>no image</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={getCropData}>Crop</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
