import React, { useState } from 'react';
import { useStyles } from './UpcomingProductInfo.style';
import { Typography } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import moment from 'moment';

const UpcomingProductInfo = ({data}) => {
  const classes = useStyles();
  const [productImgLoaded, setProductImgLoaded] = useState(false);
  const {
    name,
    releaseDate,
    images,
    description,
  } = data;

  const imageCarousel = (images) => {
    const productImages = images.map((image) => {
      return (
        <img
          alt='product'
          style={{
            objectFit: 'cover',
            aspectRatio: '1',
            width: `${productImgLoaded ? '100%' : 464}`,
            transition: 'all 0.3s ease',
          }}
          key={image}
          src={image}
          onLoad={() => setProductImgLoaded(true)}
        />
      )
    });
    return productImages;
  };

  return (
    <React.Fragment>
      {releaseDate &&
        <div className={classes.container}>
          <div className={classes.subContainer}>
            <div className={classes.imageContainer}>
              <Carousel
                emulateTouch={true}
                showThumbs={false}
                showStatus={false}
              >
                {imageCarousel(images)}
              </Carousel>
            </div>
            <div className={classes.informationContainer}>
              <Typography variant='h1' className={classes.name}>
                {name}
              </Typography>
              <Typography variant='subtitle1' style={{color: '#828282'}}>
                RELEASE DATE
              </Typography>
              <Typography variant='h3' className={classes.date}>
                {moment(new Date(releaseDate.seconds * 1000)).format('MM/DD/YY')}
              </Typography>
            </div>
          </div>
          <Typography variant='body1' className={classes.description}>
            {description}
          </Typography>
        </div>
      }
    </React.Fragment>
  );
};

export default UpcomingProductInfo;