import {
  setOpenDownloadAppModal,
  setOpenFinishSignUpModal
} from './actions';

const initialState = {
  openDownloadAppModal: false,
  openFinishSignUpModal: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setOpenDownloadAppModal:
      return { ...state, openDownloadAppModal: action.value };
    case setOpenFinishSignUpModal:
      return { ...state, openFinishSignUpModal: action.value };
    default:
      return state;
  }
};

export default reducer;
