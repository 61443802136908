import React, { useState, useEffect } from 'react';
import { useStyles } from './AdminCanceledOrders.style';
import { IconButton } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';

import MenuIcon from '@material-ui/icons/Menu';

import { getCanceledOrders } from '../../../utils/firebaseFunctions/order';

/**
 * Renders canceled orders for fullfilment purposes
 * @returns Grid view of canceled orders
 */

const AdminCanceledOrders = () => {
  const classes = useStyles();
  const [orderData, setOrderData] = useState([]);

  // *** Custom Column Render Functions ***
  const renderStatusLink = (params) => {
    return (
      <div>
        {params.row.shippingLabel
          ? <a target='_blank' rel='noopener noreferrer' href={params.row.shippingLabel} >
            {params.row.orderStatus}
          </a>
          : <div>
            {params.row.orderStatus}
          </div>
        }
      </div>
    );
  };

  const renderTrackingLink = (params) => {
    return (
      <div>
        {params.row.trackingId
          ? <a target='_blank' rel='noopener noreferrer' href={params.row.trackingStatus} >
            {params.row.trackingId}
          </a>
          : <div>

          </div>
        }
      </div>
    );
  };

  const renderOptionsButton = () => {
    return (
      <IconButton
        color='primary'
        size='small'
        onClick={() => {
          handleOptionsClick()
        }}
      >
        <MenuIcon />
      </IconButton>
    );
  };

  const handleOptionsClick = () => {
    // console.log('menu')
  };

  // *** DataGrid Column and Row formatting ***
  const orderColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
    },
    {
      field: 'buyer',
      headerName: 'Buyer',
      width: 120,
    },
    {
      field: 'seller',
      headerName: 'Seller',
      width: 120,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: renderStatusLink,
      width: 180,
    },
    {
      field: 'tracking',
      headerName: 'Tracking',
      renderCell: renderTrackingLink,
      width: 150,
    },
    {
      field: 'priceNet',
      headerName: 'Price | Net',
      width: 150,
    },
    {
      field: 'moreOptions',
      headerName: 'Options',
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderOptionsButton,
      width: 80,
    },
  ];

  const orderRows = (orderData) => {
    const orders = orderData.map((order) => {
      const label = order.shippingLabel ? order.shippingLabel.label_url : null;
      const trackingId = order.seller_shippingLabel ? order.seller_shippingLabel.tracking_number : null;
      const trackingStatus = order.seller_shippingLabel ? order.seller_shippingLabel.tracking_url_provider : null;

      return (
        {
          id: order.orderNumber,
          buyer: order.buyer,
          date: new Date(order.createdAt.seconds * 1000),
          seller: order.product.nickname,
          name: order.product.name,
          status: renderStatusLink,
          tracking: renderTrackingLink,
          priceNet: `$${order.product.price} | $${order.netProceeds} `,
          // update below to actually order ID
          trackingId: trackingId,
          trackingStatus: trackingStatus,
          orderId: order.buyerId,
          shippingLabel: label,
          orderStatus: order.orderStatus,
        }
      );
    });

    return orders;
  };

  const fetchOrders = async () => {
    const orders = await getCanceledOrders();
    // console.log(orders.map(order => order))
    setOrderData(orders);
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className={classes.container}>
      <DataGrid
        columns={orderColumns}
        rows={orderRows(orderData)}
      />
    </div>
  );
};

export default AdminCanceledOrders;