import firebase from 'firebase';

const getProductsWithId = async (styleId, limit) => {
  const products = await firebase
    .firestore()
    .collection('products')
    .where('sold', '==', false)
    .where('deleted', '==', false)
    .where('styleId', '==', styleId)
    .orderBy('createdAt', 'desc')
    .limit(limit)
    .get();

  const productsDocs = products.docs;

  return productsDocs;
};

export default getProductsWithId;
