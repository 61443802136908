import {
  CHANGE_LOGIN_STATUS,
  SET_USER_PROFILE,
  SET_USER_ID,
  SET_USER_GROUP
} from '../actionTypes';

const initialState = {
  loginStatus: sessionStorage.getItem('user') ? true : false,
  userId: sessionStorage.getItem('user') ? sessionStorage.getItem('user') : '',
  userProfile: sessionStorage.getItem('userProfile') ? sessionStorage.getItem('userProfile') : '',
  userGroup: sessionStorage.getItem('userGroup') ? sessionStorage.getItem('userGroup') : 'default',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LOGIN_STATUS: {
      const { loginStatus } = action;
      return { ...state, loginStatus };
    }
    case SET_USER_PROFILE: {
      const { userProfile } = action;
      return { ...state, userProfile };
    }
    case SET_USER_ID: {
      const { userId } = action;
      return { ...state, userId };
    }
    case SET_USER_GROUP: {
      const { userGroup } = action;
      return { ...state, userGroup };
    }
    default:
      return state;
  }
};

export default reducer;
