import firebase from './firebase';

//Function to create a new firestore document on 'users' collections
//with the provided user id

const firestoreCreateUser = async ({ email, uid, photoURL, firstName, lastName, nickname }) => {
  try {
    // const [lastName, firstName] = displayName
    //   ? displayName.split(" ")
    //   : fullName.split(" ").reverse();
    const userDoc = firebase.firestore().collection('users').doc(uid);
    const userPrivDoc = userDoc.collection('private').doc('doc');
    const subDoc = firebase.firestore().collection('subscriptions').doc(uid);
    await Promise.all([
      userDoc.set({
        profileImg:
          photoURL ||
          'https://firebasestorage.googleapis.com/v0/b/glowpop-b11fc.appspot.com/o/images%2Fuser.png?alt=media&token=0a761c04-1dbe-4848-8be2-9d074970f108',
        createdAt: new Date(Date.now()),
        subscriptions: [],
        products: [],
        about: '',
        nickname: nickname ? nickname : '',
        averagerating: 0,
        reviewcount: 0,
        followerscount: 0,
        followingcount: 0,
        shoecount: 0,
        //pushtoken: pushtoken.data, //TODO
      }),
      userPrivDoc.set({
        email,
        firstName: firstName ? firstName : '',
        lastName: lastName ? lastName : '',
      }),
      subDoc.set({
        subscribers: [],
      }),
    ]);
    return (true);
  } catch (e) {
    if (e.code === 'auth/email-already-in-use') {
      alert(
        'An account already exists for the provided email. Please use another email.'
      );
    } else {
      // console.log(e);
      alert('Sorry cannot login at the moment.');
      return (false);
    }
  }
};

export default firestoreCreateUser;
