import React, { useEffect, useState } from 'react';
import { useStyles } from './MoreProducts.style';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import firebase from '../../../utils/firebase';
import ProductCard from '../../ShoeCards/ProductCard';

const MoreProducts = ({ productData }) => {
  const history = useHistory();
  const [moreProducts, setMoreProducts] = useState([]);
  const { nickname, userId, currentProductID } = productData;
  const classes = useStyles();

  useEffect(() => {
    fetchMoreProducts();
    //eslint-disable-next-line
  }, []);

  const fetchMoreProducts = async () => {
    let productIDCollection = await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .collection('products')
      .get();

    productIDCollection = productIDCollection.docs.map(
      el => el.data().productId
    );

    let productDataCollection = await Promise.all(
      productIDCollection
        .filter(productID => productID !== currentProductID)
        .map(productID =>
          firebase.firestore().collection('products').doc(productID).get()
        )
        .slice(0, 12)
    );

    setMoreProducts(productDataCollection);
  };

  return (
    <div className={classes.moreProductsContainer}>

      <div className={classes.container}>
        <Typography
          style={{ fontWeight: 'bold' }}
          variant='h2'
        >
          {`More from ${nickname}`}
        </Typography>

        <Typography
          onClick={() => history.push(`/profile/${nickname}`)}
          className={classes.viewMore}
          variant='body1'
        >
          {'View All'}
        </Typography>
      </div>


      {moreProducts.length === 0 ? (
        <div
          style={{height: 'auto', flexGrow: 1}}
        >No products listed</div>
      ) : (
        <Grid
          className={classes.container}
          spacing={2}
          container
          direction='row'
          style={{justifyContent: 'left'}}
        >
          {
           // eslint-disable-next-line
          moreProducts.map((product, i) => {
            const productData = product.data();
            if (productData){
              return (
                <Grid
                  xs={4}
                  md={2}
                  item
                  key={`${nickname}_products_${i}`}
                  className={classes.moreProductsCardContainer}
                  onClick={() => {
                    history.push(`/product/${product.id}`);
                    history.go(0);
                  }}
                >
                  <ProductCard hit={productData} />
                </Grid>
              );
            }
          })
          }
        </Grid>
      )}
    </div>
  );
};

export default MoreProducts;

