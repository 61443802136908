import firebase from '../firebase';

export const getPosts = async (limit) => {
  try {
    const postDocQuery = await firebase
      .firestore()
      .collection('posts')
      .orderBy('createdAt', 'desc')
      .limit(limit)
      .get();

    const postsDocs = postDocQuery.docs;
    return postsDocs;
  } catch (e) {
    return (e);
  }
};

export const getAdminPosts = async (limit, adminUid) => {
  try {
    const postDocQuery = await firebase
      .firestore()
      .collection('posts')
      .where('userId', '==', adminUid)
      .orderBy('createdAt', 'desc')
      .limit(limit)
      .get();

    const postsDocs = postDocQuery.docs;
    return (postsDocs);
  } catch (e) {
    return (e);
  }
};


export const postLike = async (userId, postId) => {
  try {
    const currentTime = new Date(Date.now());
    await firebase
      .firestore()
      .collection('posts')
      .doc(postId)
      .collection('likes')
      .doc(userId)
      .set({
        userId: userId,
        currentTime: currentTime,
      });
    await firebase
      .firestore()
      .collection('posts')
      .doc(postId)
      .update({
        likedBy: firebase.firestore.FieldValue.arrayUnion(userId),
        likesCount: firebase.firestore.FieldValue.increment(1),
      });
    // success
    return (true);
  } catch (e) {
    // error
    return (Error('postLike rejected'));
  }
};

export const postUnlike = async (userId, postId) => {
  try {
    await firebase
      .firestore()
      .collection('posts')
      .doc(postId)
      .collection('likes')
      .doc(userId)
      .delete();
    await firebase
      .firestore()
      .collection('posts')
      .doc(postId)
      .update({
        likedBy: firebase.firestore.FieldValue.arrayRemove(userId),
        likesCount: firebase.firestore.FieldValue.increment(-1),
      });
    // success
    return (true);
  } catch (e) {
    // error
    return (Error('postUnlike rejected'));
  }
};

export const getLikeCount = async (postId) => {
  try {
    const postDoc = await firebase
      .firestore()
      .collection('posts')
      .doc(postId)
      .get();

    if (postDoc.exists) {
      const likeCount = postDoc.data().likesCount;
      if (likeCount) {
        // success
        return (likeCount);
      }
    }
  } catch (e) {
    // error
    return (Error('getLikeCount rejected'));
  }
};

export const likeCheck = async (postId, userId) => {
  try {
    const likesDoc = await firebase
      .firestore()
      .collection('posts')
      .doc(postId)
      .collection('likes')
      .where('userId', '==', userId)
      .get();

    const likeCheck = likesDoc.docs;

    if (likeCheck.length > 0) {
      return (true);
    } else {
      return (false);
    }
  } catch (e) {
    // error
    return (Error('likeCheck rejected'));
  }
};
