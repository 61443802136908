export const resendSellerEmail = async (params, email) => {
  // const API_URL ='https://us-central1-glowpop-b11fc.cloudfunctions.net/resendSellerEmail';
  const API_URL ='http://localhost:5002/glowpop-b11fc/us-central1/resendSellerEmail';

  const response = await fetch(`${API_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      email,
      // product: params.product,
      // orderNumber: params.orderNumber,
      // orderStatus: params.orderStatus,
      // shippingLabel: params.seller_shippingLabel,
      // netProceeds: params.netProceeds,
      // trackingLink: params.sellerTrackingLink,
      // deadline: params.deadline,
      // authentication: ,
    },
  });
  await console.log(response.json());
};

