import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  productContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
    paddingTop: 'min(4%, 40px)',
    alignSelf: 'center',
    justifyContent: 'center',
    columnGap: '16px',
    flexWrap: 'wrap',
    width: '100%',
  },
  imageContainer: {
    flex: 1,
    flexWrap: 'wrap',
    minWidth: '344px',
    maxWidth: '558px',
  },
  image: {
    opacity: 0,
    transition: 'opacity 0.6s',
    objectFit: 'cover',
    aspectRatio: '1',
  },
  informationContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
    flexWrap: 'wrap',
    minWidth: '270px',
    maxWidth: '558px',
    textAlign: 'justify',
  },
  likeButton: {
    height: 32,
    color: '#828282',
    marginLeft: 8,
    cursor: 'pointer',
    '&:hover': {
      color: '#BDBDBD',
    },
  },
  filledlikeButton: {
    height: 32,
    color: '#EA3323',
    marginLeft: 8,
    cursor: 'pointer',
  },
  button:{
    backgroundColor: 'black',
    marginTop: 24,
    marginBottom: 16,
    color: 'white',
    textTransform: 'none',
    borderRadius: 4,
    height: 48,
  },
  avatarContainer: {
    display: 'flex',
    backgroundColor: '#EEEEEE',
    borderRadius: '999px',
    width: '48px',
    height: '48px',
    cursor: 'pointer',
  },
  small: {
    width: '48px',
    height: '48px',
  },
  avatarImg: {
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.6s',
    borderRadius: '999px',
  },
  imgLoaded: {
    opacity: 1,
  },
}));