import firebase from './firebase';

const fetchProductDetails = async (productID) => {
  try {
    let productDoc = await firebase
      .firestore()
      .collection('products')
      .doc(productID)
      .get();
    return (productDoc.data());
  } catch (e) {
    return (null);
  }
};

export default fetchProductDetails;