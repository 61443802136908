import React, { useRef } from 'react';
import { useStyles } from './UpcomingCarousel.style';
import { useHistory } from 'react-router-dom';
import {
  useTheme,
  Typography,
  IconButton,
  useMediaQuery,
  Grid
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import UpcomingCard from '../../ShoeCards/UpcomingCard';

const UpcomingCarousel = (props) => {
  const { title, data } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const scrollEl = useRef(null);

  /** scrollOffset: changes distance traveled */
  const scrollLeft = (scrollOffset) => {
    /** x: changes speed and distance */
    const x = 6;
    for (let i=0; i>scrollOffset; i--) {
      setTimeout(() => (scrollEl.current.scrollLeft -= x), Math.abs(i) * 2);
    }
  };

  /** scrollOffset: changes distance traveled */
  const scrollRight = (scrollOffset) => {
    /** x: changes speed and distance */
    const x = 8;
    for (let i=0; i<scrollOffset; i++) {
      setTimeout(() => (scrollEl.current.scrollLeft += x), i * 2);
    }
  };

  const handleLeftClick = () => {
    scrollLeft(-80);
  };
  const handleRightClick = () => {
    scrollRight(80);
  };

  const handleSeeAllClick = () => {
    history.push({
      pathname: 'upcoming/all',
    });
  };

  return (
    <div className={classes.container} >
      <div
        style={{
          overflowX: 'hidden',
        }}
      >
        <Grid container justify='space-between'>
          <Typography display='inline' variant='h2' style={{fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Typography
            onClick={handleSeeAllClick}
            display='inline'
            variant='body2'
            style={{ cursor: 'pointer', color: '#0000FD', textAlign: 'right'
          }}>
            See All
          </Typography>
        </Grid>
        <div className={classes.itemsContainer} ref={scrollEl}>
          {data.map((item, i) => (
            <UpcomingCard
              itemId={item.sku}
              name={item.name}
              key={item.sku + i}
              imageUrl={item.images[0]}
              releaseDate={item.releaseDate ? item.releaseDate : null}
            />
          ))}
        </div>
      </div>
      {useMediaQuery(theme.breakpoints.up('md')) &&
        <IconButton variant='contained' className={classes.leftArrowButton} onClick={handleLeftClick}>
          <ChevronLeftIcon className={classes.leftArrow} />
        </IconButton>
      }
      {useMediaQuery(theme.breakpoints.up('md')) &&
        <IconButton variant='contained' className={classes.rightArrowButton} onClick={handleRightClick}>
          <ChevronRightIcon className={classes.rightArrow} />
        </IconButton>
      }
    </div>
  );
};

export default UpcomingCarousel;
