import firebase from './firebase';
import firestoreUpdateLogin from './firestoreUpdateLogin';
import { setOpenFinishSignUpModal } from '../redux/entities/modals/actions';
import { UPDATE_PENDING_SIGNUP_INFO } from '../redux/entities/signUp/actions';

const googleSignin = async (dispatch) => {
  let resp = { success: false, new: false, uid: '' };

  try {
    let provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'consent' });
    let result = await firebase.auth().signInWithPopup(provider);

    if (result.additionalUserInfo.isNewUser) {
      // console.log("[googleSignin] newuser");
      const email = result.additionalUserInfo.profile.email
      const uid = result.user.uid
      const photoURL = result.additionalUserInfo.profile.picture
      dispatch({
        type: UPDATE_PENDING_SIGNUP_INFO,
        value: {
          email: email,
          photoURL: photoURL,
          uid: uid,
        }
      });
      resp.new = true;
      dispatch({ type: setOpenFinishSignUpModal, value: true });
    } else {
      // console.log("[googleSignin] existing user");
      resp.success = await firestoreUpdateLogin(result.user);
    }
    resp.uid = result.user.uid;
    return resp;
  } catch (e) {
    var errorMessage = e.message;
    console.error(errorMessage);
    return (resp);
  }
};

export default googleSignin;
