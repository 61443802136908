import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexGrow: 1,
    },
    justifyContent: 'right',
  },
}));