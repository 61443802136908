import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Button
} from '@material-ui/core';
import googleSignin from '../../../utils/googleSignin';
import facebookSignin from '../../../utils/facebookSignin';
import appleSignin from '../../../utils/appleSignin';
import { appleLogo, fbLogoColor, googleLogoColor } from '../../../assets/social_logos/logos';
import { useHistory } from 'react-router-dom';
// import verifyUniqueNickname from "../utils/verifyUniqueNickname";

const SocialLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();


  const handleSocialLogin = async type => {
    let resp =
      type === 'google'
        ? await googleSignin(dispatch)
        : type === 'facebook'
          ? await facebookSignin(dispatch)
          : type === 'apple'
            ? await appleSignin(dispatch)
            : null;
    if (resp.success) {
      // updateUserState(resp.uid);
      history.push('/loading')
    }
  };

  return (
    <div>
      <Button
        onClick={() => handleSocialLogin('apple')}
        style={{ width: '100%', height: 56, textTransform: 'none', borderRadius: '4px' }}
        variant='outlined'
        color='primary'
      >
        <img src={appleLogo} alt='google logo' style={{ height: 20, marginRight: 8 }} />
        <Typography style={{ fontWeight: 'bold' }} > Continue with Apple</Typography>
      </Button>

      <Button
        onClick={() => handleSocialLogin('google')}
        style={{ width: '100%', height: 56, textTransform: 'none', marginTop: 8 }}
        variant='outlined'
        color='primary'
      >
        <img src={googleLogoColor} alt='google logo' style={{ height: 20, marginRight: 8 }} />
        <Typography style={{ fontWeight: 'bold' }} > Continue with Google</Typography>
      </Button>

      <Button
        onClick={() => handleSocialLogin('facebook')}
        style={{ width: '100%', height: 56, textTransform: 'none', marginTop: 8 }}
        variant='outlined'
        color='primary'
      >
        <img src={fbLogoColor} alt='facebook logo' style={{ height: 20, marginRight: 8 }} />
        <Typography style={{ fontWeight: 'bold' }} > Continue with Facebook</Typography>
      </Button>
    </div>
  );
};

export default SocialLogin;

