import firebase from '../firebase';

const getRecentProducts = async (limit) => {
  const products = await firebase
    .firestore()
    .collection('products')
    .orderBy('createdAt', 'desc')
    .where('sold', '==', false)
    .where('deleted', '==', false)
    .where('condition', '==', 'New with box')
    .limit(limit)
    .get();

  let lastItem = '';

  const productsDocs = products.docs.map(el => {
    lastItem = el.data().createdAt;
    return {...el.data(), id: el.id};
  });

  return {productsDocs, lastItem};
};

const getMoreRecentProducts = async (limit, lastLoaded) => {
  const products = await firebase
    .firestore()
    .collection('products')
    .orderBy('createdAt', 'desc')
    .where('sold', '==', false)
    .where('deleted', '==', false)
    .where('condition', '==', 'New with box')
    .startAfter(lastLoaded)
    .limit(limit)
    .get();

  let lastItem = '';

  const productsDocs = products.docs.map(el => {
    lastItem = el.data().createdAt;
    return {...el.data(), id: el.id};
  });

  return {productsDocs, lastItem};
};

export {getRecentProducts, getMoreRecentProducts};
