import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
    fontSize: '28px',
    marginBottom: 40,
    marginTop: 40,
    display: 'flex',
    justifyContent: 'center'
  },
  fillForm: {
    width: '100%',
    marginBottom: 8,
  },
  button: {
    width: '100%',
    marginBottom: '24px',
    textTransform: 'none',
    height: 56
  },
  link: {
    cursor: 'pointer',
    color: '#0645AD',
    textDecoration: 'underline',
  }
}));