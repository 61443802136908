import React, { useEffect, useState } from 'react';
import { useStyles } from './ProfileEdit.style';
import {
  Container,
  Grid,
  Avatar,
  TextField,
  Button,
  CircularProgress
} from '@material-ui/core';
import { connect } from 'react-redux';
import { setUserProfile } from '../../../redux/actions';
import firebase from '../../../utils/firebase';
import {
  getUserDoc,
  getUserPrivateDoc,
  checkNickname,
  updateProfile,
} from '../../../utils/firebaseFunctions/user';

const ProfileEdit = props => {
  const classes = useStyles();
  const { userId, setProfileRedux } = props;
  const [loading, setLoading] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [about, setAbout] = useState('')
  // states for nickname verification:
  const [nickname, setNickname] = useState('')
  const [originalNickname, setOriginalNickname] = useState('')
  const [nicknameHelperText, setNicknameHelperText] = useState('')
  const [verifiedNickname, setVerifiedNickname] = useState(true)
  // states for profile img uploading:
  const [profile, setProfile] = useState('')
  const [profileUploading, setProfileUploading] = useState(false)

  useEffect(() => {
    fetchUserData();
  }, []);


  useEffect(() => {
    performNicknameCheck()
  }, [nickname])

  const performNicknameCheck = async () => {
    if (nickname.length > 19) {
      setNicknameHelperText('Your nickname must be less than 18 characters')
      setVerifiedNickname(false)
    } else {
      const result = await checkNickname(nickname)
      if (result) {
        setNicknameHelperText('Available nickname!')
        setVerifiedNickname(true)
      } else if (nickname === originalNickname) {
        setNicknameHelperText('')
        setVerifiedNickname(true)
      } else {
        setNicknameHelperText('Unavailable nickname')
        setVerifiedNickname(false)
      }
    }
  }

  const fetchUserData = async () => {
    const userData = await getUserDoc(userId)
    const userDataPrivate = await getUserPrivateDoc(userId)
    setOriginalNickname(userData.nickname)
    setNickname(userData.nickname)
    setProfile(userData.profileImg)
    setFirstName(userDataPrivate.firstName)
    setLastName(userDataPrivate.lastName)
    if (userData.about) setAbout(userData.about)
    setLoading(false)
  }

  const handleUpdateProfile = async () => {
    setLoading(true)
    if (!verifiedNickname) {
      alert('Please verify nickname')
    } else if (about.length > 100) {
      alert('About must be less than 100 characters')
    } else {
      const updateData = {
        nickname,
        about,
        profileImg: profile,
        firstName,
        lastName
      }
      try {
        const result = await updateProfile(userId, updateData)
        if (result) {
          setProfileRedux(profile)
          alert('Profile updated!')
        }
      } catch (e) {
        alert('Profile update failed!')
      }
    }
    setLoading(false)
  }

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleNicknameChange = (event) => {
    setNickname(event.target.value);
  };

  const handleAboutChange = (event) => {
    setAbout(event.target.value);
  };

  const handleUploadClick = async (event) => {
    setProfileUploading(true)
    try {
      const img = event.target.files[0]
      const imgRef = await firebase.storage().ref(`profile_images/${img.name}`);
      await imgRef.put(img);
      const profileUri = await imgRef.getDownloadURL();
      setProfile(profileUri)
      setProfileUploading(false)
    } catch (e) {
      // console.log("[ProfileEdit]: uploading failed")
      setProfileUploading(false)
    }
  }

  return (
    <div className={classes.root}>
      <Container maxWidth='sm' >
        {!loading ?
          <Grid
            style={{ marginTop: '16px' }}
            container
            spacing={2}
            direction='row'
          >
            <Grid item xs={12} style={{ paddingTop: '16px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Avatar style={{ width: '150px', height: '150px' }} alt={nickname} src={profile} />
              <Button
                style={{ marginTop: '8px', marginBottom: '24px' }}
                variant='outlined'
                color='primary'
                component='label'
                disabled={profileUploading ? true : false}
              >
                Edit Photo
                <input
                  style={{ display: 'none' }}
                  type='file'
                  accept='image/*'
                  className={classes.input}
                  id='contained-button-file'
                  onChange={handleUploadClick}
                />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <TextField onChange={handleFirstNameChange} value={firstName} style={{ width: '100%' }} id='first-name-text' label='First name' variant='outlined' />
            </Grid>
            <Grid item xs={6}>
              <TextField onChange={handleLastNameChange} value={lastName} style={{ width: '100%' }} id='last-name-text' label='Last name' variant='outlined' />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleNicknameChange}
                helperText={nicknameHelperText}
                value={nickname}
                style={{ width: '100%' }}
                id='nickname-name-text'
                label='Nickname'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={about}
                onChange={handleAboutChange}
                style={{ width: '100%' }}
                id='about-text'
                label='Include description, refs, contact info and anything else you want'
                multiline
                rows={4}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => handleUpdateProfile()} style={{ width: '100%' }} variant='contained' color='primary'>Save</Button>
            </Grid>
          </Grid>
          :
          <Grid
            className={classes.container}
            container
            spacing={0}
            justify='center'
            alignItems='center'
          >
            <CircularProgress />
          </Grid>
        }
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  const { userId } = state.reducer;
  return { userId };
};

const mapDispatchToProps = dispatch => {
  return {
    setProfileRedux: userProfile => dispatch(setUserProfile(userProfile)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
