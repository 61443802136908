import React, { useEffect, useState } from 'react';
import { useStyles } from './AdminPostUploader.style';
import ReactJson from 'react-json-view';
import { uid } from 'uid';
import firebase from '../../../utils/firebase';

import {
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';

import AdminImageCropper from '../AdminImageCropper';
import { getUserDoc } from '../../../utils/firebaseFunctions/user';


export default function AdminPostUploader() {
  const classes = useStyles();
  const [post, setPost] = useState({});
  const [tempImages, setTempImages] = useState([]);
  const [openCropModal, setOpenCropModal] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const userId = sessionStorage.getItem('user');
    const userData = await getUserDoc(userId);

    const postData = {
      userId: userId,
      nickname: userData.nickname,
      profileImg: userData.profileImg,
      likesCount: 0,
      commentsCount: 0,
      deleted: false,
      type: '',
      description: '',
      createdAt: '(created onSubmit)',
      images: '(created onSubmit)',
    };
    setPost(postData);
  };

  const onChangeFileSelect = async (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const requests = Object.keys(files).map((i) => readFile(files, i));
    Promise.all(requests)
      .then((values) => {
        const tempImageArr = values.map((val) => ({ uid: uid(), image: val }));
        setTempImages(tempImageArr);
        setOpenCropModal(true);
      })
      .catch((e) => alert(`Error uploading images: ${e}`));
  };

  const disableButton = () => {
    return (
      !post ||
      Object.keys(post).some((key) => post[key] === '') ||
      tempImages.length === 0 ||
      tempImages.some((img) => !img.croppedImage)
    );
  };

  const handlePost = async () => {
    try {
      const createdAt = new Date();
      const userId = sessionStorage.getItem('user');

      const imageURLs = await _uploadImages(
        tempImages.map((img) => img.croppedImage)
      );
      console.debug('imageURLs', imageURLs);
      post.images = imageURLs;
      post.createdAt = createdAt;
      const upload = await firebase
        .firestore()
        .collection('posts')
        .add(post)
        .catch(function (error) {
          console.error('Error adding post doc', error);
          throw new Error(error);
        });

      if (upload) {
        console.debug('Post created successfully ', upload.id);
        firebase
          .firestore()
          .collection('users')
          .doc(userId)
          .collection('posts')
          .doc(upload.id)
          .set({
            postId: upload.id,
            currentTime: createdAt,
            deleted: false,
          })
          .then(() => {
            console.debug('User data updated successfully');
            alert('Post upload success: ', upload.id);
            getUserData(); // reset
            setTempImages([])
          })
          .catch((error) => {
            console.error('Error updating user doc', error);
            throw new Error(error);
          });
      }
    } catch (e) {
      console.debug('Post upload failed: ', e);
      alert('Post upload failed (see debug logs) ');
    }
  };

  return (
    <div className={classes.container}>
      <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
        Upload a post
      </Typography>
      {post.type !== undefined ? (
        <FormControl className={classes.input} variant='outlined' size='small'>
          <InputLabel>Type</InputLabel>
          <Select
            value={post.type}
            onChange={(event) => setPost({ ...post, type: event.target.value })}
            variant='outlined'
            size='small'
            labelWidth={40}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={'buying'}>buying</MenuItem>
            <MenuItem value={'trading'}>trading</MenuItem>
            <MenuItem value={'legit check'}>legit check</MenuItem>
            <MenuItem value={'flex'}>flex</MenuItem>
            <MenuItem value={'style'}>style</MenuItem>
            <MenuItem value={'upcoming'}>upcoming</MenuItem>
          </Select>
        </FormControl>
      ) : null}

      <TextField
        multiline
        rows={3}
        className={classes.input}
        size='small'
        id='outlined-body'
        label='Description'
        value={post.description}
        onChange={(event) =>
          setPost({ ...post, description: event.target.value })
        }
        variant='outlined'
      />
      <div className={classes.input}>
        <input type='file' multiple onChange={onChangeFileSelect} />
        <div
          style={{
            flex: 1,
            height: 75,
            flexDirection: 'row',
            overflowX: 'auto',
            marginTop: 8,
          }}
        >
          {tempImages.every((img) => img.croppedImage) &&
            tempImages.map((image) => (
              <img
                key={image.uid}
                alt={image.uid}
                src={image.croppedImage}
                style={{ height: 60, width: 'auto', marginRight: 8 }}
              />
            ))}
        </div>
      </div>

      <div style={{ flexGrow: 1 }}>
        <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
          Review post document:
        </Typography>
        <ReactJson src={post} />
      </div>
      <Button
        onClick={() => handlePost()}
        variant='contained'
        color='primary'
        size='small'
        style={{ marginTop: 8 }}
        disabled={disableButton()}
      >
        Upload Post
      </Button>
      <AdminImageCropper
        open={openCropModal}
        setOpen={setOpenCropModal}
        images={tempImages}
        setImages={setTempImages}
      />
    </div>
  );
}

// helper util functions:

const readFile = (files, i) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(files[i]);
    } catch (e) {
      reject(e);
    }
  });
};

const _uploadImages = async (images) => {
  const uploadURLs = await Promise.all(
    images.map(async (item) => await _uploadImage(item))
  );
  return uploadURLs;
};

const _uploadImage = async (imageURI) => {
  const response = await fetch(imageURI);
  const blob = await response.blob();
  const ext = blob.type.split('/')[1];
  const filename = `${uid()}.${ext}`;
  const imgRef = firebase.storage().ref(`product_images/${filename}`);
  await imgRef.put(blob);
  return imgRef.getDownloadURL();
};
