import React from 'react';
import { useStyles } from './LoginSignUpButtons.style'
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { Breadcrumbs, Hidden } from '@material-ui/core';

const LoginSignUpButtons = () => {
  const classes = useStyles();
  const history = useHistory({ forceRefresh: true });

  return (
      <Hidden smDown>
        <div className={classes.container}>
        <Breadcrumbs separator=' ' aria-label='breadcrumb'>
          <Button
            variant='outlined'
            onClick={() => history.push('/signin')}
            className={classes.loginButton}
          >
            <Typography
              variant='body2'
              className={classes.loginText}
            >
              Login
            </Typography>
          </Button>

          <Button
            variant='contained'
            onClick={() => history.push('/signup')}
            className={classes.signUpButton}
          >
            <Typography
              variant='body2'
              className={classes.signUpText}
            >
              Sign up
            </Typography>
          </Button>
        </Breadcrumbs>
      </div>
    </Hidden>
  );
};

export default LoginSignUpButtons;