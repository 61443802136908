import React, { useEffect, useState } from 'react';
import { useStyles } from './SignUp.style';
import { useHistory } from 'react-router-dom';
import {
  Container,
  TextField,
  Typography,
  Button
} from '@material-ui/core';
import SocialLogin from '../../components/Login/SocialLogin';
import { setOpenFinishSignUpModal } from '../../redux/entities/modals/actions';
import { useDispatch } from 'react-redux';
import { UPDATE_PENDING_SIGNUP_INFO, CLEAR_SIGNUP_INFO } from '../../redux/entities/signUp/actions';

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const dispatch = useDispatch();

  const validateForm = () => {
    if (email.length === 0
      || password.length === 0
      || repassword.length === 0
    ) {
      alert('Make sure to fill out all information')
      return false;
    } else if (!validateEmail()) {
      alert('Please enter a valid email address')
      return false;
    } else if (password !== repassword) {
      alert('Please make sure that your passwords match');
      return false;
    } else if (password.length < 6) {
      alert('Your password needs to be longer than six characters');
      return false;
    }
    return true;
  };

  /** https://www.w3resource.com/javascript/form/email-validation.php */
  const validateEmail = () => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return (true);
    } else {
      return (false);
    }
  };

  const handleEnterPress = e => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    let validated = validateForm();
    if (validated) {
      dispatch({
        type: UPDATE_PENDING_SIGNUP_INFO,
        value: {
          email: email,
          password: password
        }
      });
      dispatch({ type: setOpenFinishSignUpModal, value: true })
    }
  };

  const handleEmailInput = e => {
    setEmail(e.target.value);
  };
  const handlePasswordInput = e => {
    setPassword(e.target.value);
  };
  const handleRepasswordInput = e => {
    setRepassword(e.target.value);
  };

  useEffect(() => {
    dispatch({ type: CLEAR_SIGNUP_INFO })
  }, []);

  return (
    <Container maxWidth='sm' >
      <Typography className={classes.title}>
        Sign Up
      </Typography>
      <TextField
        onKeyPress={handleEnterPress}
        onChange={handleEmailInput}
        value={email}
        className={classes.fillForm}
        label='Email'
        variant='outlined'
      />
      <TextField
        onKeyPress={handleEnterPress}
        onChange={handlePasswordInput}
        value={password}
        className={classes.fillForm}
        label='Password'
        variant='outlined'
        type='password'
      />
      <TextField
        onKeyPress={handleEnterPress}
        onChange={handleRepasswordInput}
        value={repassword}
        className={classes.fillForm}
        label='Re-enter Password'
        variant='outlined'
        type='password'
      />
      <div style={{ borderBottom: '1px solid #E0E0E0', marginBottom: 24 }}>
        <Button
          onClick={() => handleSubmit()}
          className={classes.button}
          variant='contained'
          color='primary'
        >
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>
            Sign Up
          </Typography>
        </Button>
      </div>
      <SocialLogin />
      <div align='center' style={{ marginTop: 40 }}>
        <Typography display='inline' >
          Already have an account?
          &nbsp;
        </Typography>
        <Typography
          display='inline'
          onClick={() => history.push('/signin')}
          className={classes.link}
        >
          Sign in
        </Typography>
      </div>
    </Container>
  );
};

export default SignUp;
