import React, { useState, useEffect } from 'react';
import { useStyles } from './SignInLoading.style';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import firebase from '../../../utils/firebase';
import { changeLoginStatus, setUserProfile, setUserId, setUserGroup } from '../../../redux/actions';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress'

const SignInLoading = ({ changeLogin, setProfileGlobal, setUserIdGlobal, setUserGroup }) => {
  const classes = useStyles();
  const history = useHistory();
  const [userId, setUserId] = useState('');
  const [userProfile, setUserProfile] = useState('');
  const [loading, setLoading] = useState(true);

  // when component first mounts
  useEffect(() => {
    setTimeout(() => {
      checkLoginStatus();
    }, 1000);
  }, []);

  // when user and userData has been verified
  useEffect(() => {
    if (!loading) {
      sessionStorage.setItem('user', userId);               // store userId in sessionStorage
      sessionStorage.setItem('userProfile', userProfile);   // store userProfile in sessionStorage
      setProfileGlobal(userProfile)
      setUserIdGlobal(userId)
      setTimeout(() => {
        changeLogin(true)
        history.push('/');                      // navigate to home
      }, 1000);
    }
  }, [loading]);

  // checks for logged in user and firestore userDoc
  const checkLoginStatus = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {                              // user logged in
        setUserId(user.uid)
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(doc => {
            const userDoc = doc.data();
            if (!userDoc) {                    // no userDoc for loggedin user
              history.push('/signin');
            } else {                           // userDoc found
              setLoading(false);
              setUserProfile(userDoc.profileImg);
              sessionStorage.setItem('userGroup', userDoc.userGroup);
              setUserGroup(userDoc.userGroup)
              // console.log("onAuthStateChanged: ", userDoc)
            }
          })
          .catch(e => {
            console.error('onAuthStateChanged: ', e)
            history.push('/signin')
          });
      }
      else {                                   // user not logged in
        // console.log("onAuthStateChanged: ", "user not logged in")
        history.push('/signin');
      }
    });
  }

  return (
    <div className={classes.root} >
      <Container maxWidth='sm' style={{ minHeight: '100vh' }}>
        <Grid container style={{ minHeight: '100vh' }}
          direction='column'
          justify='center'
          alignItems='center'>
          {loading && <CircularProgress />}
        </Grid>
      </Container>
    </div>

  );
};

const mapStateToProps = state => {
  return state.reducer;
};
const mapDispatchToProps = dispatch => {
  return {
    changeLogin: loginStatus => dispatch(changeLoginStatus(loginStatus)),
    setUserIdGlobal: userId => dispatch(setUserId(userId)),
    setProfileGlobal: userProfile => dispatch(setUserProfile(userProfile)),
    setUserGroup: userGroup => dispatch(setUserGroup(userGroup)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignInLoading);
