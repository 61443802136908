import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  searchBarContainer: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 2,
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'right',
      display: 'flex',
    },
  },
  hidden: {
    backgroundColor: '#F2F2F2',
    transition: 'all .2s ease',
  },
  notHidden: {
    backgroundColor: 'white',
    transition: 'all .2s ease',
  },
  inputContainer: {
    display: 'inline-flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent:'center',
    backgroundColor: '#F2F2F2',
    borderRadius: 4,
  },
  input: {
    width: '100%',
    height: '100%',
    border: 0,
    color: '#828282',
    fontSize: 16,
    fontFamily: 'CircularStd',
    borderRadius: 4,
    backgroundColor: '#F2F2F2',
  },
  inputIcon: {
    color: '#828282',
    marginLeft: 12,
    marginRight: 12,
  },
}));