import React, { useState, useEffect } from 'react';
import { useStyles } from './AdminOrders.style';
import {
  IconButton,
  Typography,
  Button,
  MenuItem,
  Menu,
  Modal,
  InputLabel,
  Select,
  TextField,
  FormControl,
  ButtonGroup,
  Popover,
  List,
  ListItem,
  } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';

import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';

import {
  getOrders,
  updateOrderStatus,
  getSellerPaypalInfo
} from '../../../utils/firebaseFunctions/order';
import {
  resendSellerEmail,
  resendBuyerEmail,
  openShippingLabelUrl,
} from '../../../utils/Admin';

/**
 * Renders user listing orders for fullfilment purposes
 * @returns Grid view of user listing orders
 */

const AdminOrders = () => {
  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [updateParams, setUpdateParams] = useState({});
  const [orderData, setOrderData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [buyerSeller, setBuyerSeller] = useState('');
  const [resendEmailAddress, setResendEmailAddress] = useState('');
  const [resendParams, setResendParams] = useState('');
  const [sellerAnchorEl, setSellerAnchorEl] = useState(null);
  const [buyerAnchorEl, setBuyerAnchorEl] = useState(null);
  const [paypalEmail, setPaypalEmail] = useState();
  const [paymentIntent, setPaymentIntent] = useState();
  const [shoeNameAnchorEl, setShoeNameAnchorEl] = useState(null);
  const [shoeName, setShoeName] = useState('');
  const [shoeImageUrl, setShoeImageUrl] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);

  const orderShipped = 'order-shipped';
  const sellerShipped = 'seller_shipped';
  const waitingAuth = 'waiting_authentication';
  const authFailed = 'authentication-failed';


  // *** Custom Column Render Functions ***

  const renderBuyerColumn = (params) => {
    return (
      <Typography variant='body2' onClick={(e)=> handleBuyerClick(e, params)}>{params.row.buyer}</Typography>
    );
  };

  const renderSellerColumn = (params) => {
    return (
      <Typography variant='body2' onClick={(e)=> handleSellerClick(e, params)}>{params.row.seller}</Typography>
    );
  };

  const renderStatusColumn = (params) => {
    return (
      <Select
        labelId='simple-select-label'
        id='simple-select'
        value={params.row.orderStatus}
        onChange={(e) => handleStatusUpdateClick(params.row.orderId, e.target.value, params.row.id)}
        style={{fontSize: 14}}
      >
        <MenuItem value={'waiting_seller_confimation'}>waiting_seller_confimation</MenuItem>
        <MenuItem value={'waiting_seller_shipping'}>waiting_seller_shipping</MenuItem>
        <MenuItem value={'seller_shipped'}>seller_shipped</MenuItem>
        <MenuItem value={'waiting_authentication'}>waiting_authentication</MenuItem>
        <MenuItem value={'order-shipped'}>order-shipped</MenuItem>
        <MenuItem value={'authentication-failed'}>authentication-failed</MenuItem>
        <MenuItem value={'order-delivered'}>order-delivered</MenuItem>
        <MenuItem value={'order-canceled'}>order-canceled</MenuItem>
      </Select>
    );
  };

  const renderNameColumn = (params) => {
    return (
      <Typography variant='body2' onClick={(e)=> handleNameClick(e, params)}>{params.row.name}</Typography>
    );
  };

  const renderAuthColumn = (params) => {
    return (
      <React.Fragment>
        {(params.row.orderStatus === waitingAuth) &&
          <React.Fragment>
            <IconButton
              color='primary'
              size='small'
              style={{ marginLeft: 16}}
              onClick={() => {
                handleStatusUpdateClick(params.row.orderId, orderShipped, params.row.id);
              }}
            >
              <DoneOutlineOutlinedIcon/>
            </IconButton>
            <IconButton
              color='primary'
              size='small'
              style={{ marginLeft: 16}}
              onClick={() => {
                handleStatusUpdateClick(params.row.orderId, authFailed, params.row.id);
              }}
            >
              <BlockOutlinedIcon/>
            </IconButton>
          </React.Fragment>
        }
        {params.row.orderStatus === orderShipped &&
           <Button rel='noopener noreferrer'
            onClick={() => handleShippingLabelClick(params.row.shippingLabel, params.row.orderId)}
            style={{textTransform: 'none'}}
           >
            Shipping Label
          </Button>}
      </React.Fragment>
    );
  };

  const renderTrackColumn = (params) => {
    if (params.row.orderStatus === sellerShipped){
      return (
        <IconButton target='_blank' rel='noopener noreferrer' href={params.row.sellerTrackingStatus}>
          <MarkunreadMailboxOutlinedIcon href={params.row.sellerTrackingStatus} />
        </IconButton>
        );
      } else if (params.row.shippingLabel){
        return (
        <IconButton target='_blank' rel='noopener noreferrer' href={params.row.trackingStatus}>
          <MarkunreadMailboxOutlinedIcon href={params.row.trackingStatus} />
        </IconButton>
      );
    }
  };

  const renderSellerTrackingColumn = (params) => {
    return (
      <div>
        { params.row.trackingId
          ? <a target='_blank' rel='noopener noreferrer' href={params.row.sellerShippingLabel} >
            {params.row.trackingId}
          </a>
          : <div>

          </div>
        }
      </div>
    );
  };

  const renderOptionsColumns = (params) => {
    return (
      <IconButton
      color='primary'
      size='small'
      onClick={(e) => {
        handleOptionsMenuClick(e, params)
      }}
      >
        <MenuIcon />
      </IconButton>
    );
  };

  // *** All Handle clicks for row items ***

  const handleSellerClick = async (event, params) => {
    setSellerAnchorEl(event.currentTarget);

    const  paymentInfo = await getSellerPaypalInfo(params.row.sellerId)
    if (paymentInfo[0]) {
      setPaypalEmail(paymentInfo[0].data().paypal);
    } else {
      setPaypalEmail('No listed account');
    }
  };

  const handleBuyerClick = async (event, params) => {
    setBuyerAnchorEl(event.currentTarget);
    setPaymentIntent(params.row.paymentIntent);
  };

  /** Confirmation modal actions */
  const handleStatusUpdateClick = (orderId, updatedStatus, id) => {
    setOpenConfirmation(true);

    setUpdateParams({
      orderId: orderId,
      updatedStatus:updatedStatus,
      id: id
    });
  };

  /** Shoes shoe full name and thumbnail on click */
  const handleNameClick = (event, params) => {
    setShoeNameAnchorEl(event.currentTarget);
    setShoeName(params.row.name);
    setShoeImageUrl(params.row.product.smallImages[0]);
  };

  /** More options menu */
  const handleOptionsMenuClick = (event, params) => {
    setAnchorEl(event.currentTarget);
    setBuyerSeller('');
    setResendEmailAddress('');
    /** preemptively sets params for email resend functions  */
    setResendParams({
      product: params.row.product,
      orderNumber: params.row.id,
      orderStatus: params.row.orderStatus,

      //buyer only
      shippingFees: params.row.shippingFees,
      paymentMethod: params.row.paymentMethod,
      shippingAddress: params.row.shippingAddress,
      totalAmount: params.row.totalAmount,
      trackingLink: params.row.trackingStatus,

      //seller only
      seller_shippingLabel: params.row.sellerShippingLabel,
      netProceeds: params.row.netProceeds,
      sellerTrackingLink: params.row.sellerTrackingStatus,
      deadline: params.row.deadline,
    });
  };

  /** opens Resend Email confirmation modal */
  const handleResendEmailsClick = () => {
    setAnchorEl(null);
    setOpenEmailModal(true);
  };

  /** Submits params and email to trigger resend email function in firebase then resets params */
  const handleResendSubmit = () => {
    switch (buyerSeller) {
      case 'buyer':
        resendBuyerEmail(resendParams, resendEmailAddress);
        break;
      case 'seller':
        resendSellerEmail(resendParams, resendEmailAddress);
        break;
      default:
        break;
    }
    setResendParams({});
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenConfirmation(false);
    setOpenEmailModal(false);
    setSellerAnchorEl(null);
    setBuyerAnchorEl(null);
    setShoeNameAnchorEl(null);
    setPaypalEmail();
  };

  /** Opens shiping label or fetches it if database hasn't been updated yet */
  const handleShippingLabelClick = (shippingLabel, id) => {
    if (shippingLabel) {
      window.open(shippingLabel);
    } else {
      openShippingLabelUrl(id);
    }
  };

  const handleBuyerSellerChange = (e) => {
    setBuyerSeller(e.target.value);
  };

  const handleResendEmailChange = (e) => {
    setResendEmailAddress(e.target.value);
  };

  /** confirms and sends firebase request to update orderstatus */
  const confirmUpdate = (orderId, updatedStatus) => {
    updateOrderStatus(orderId, updatedStatus);
    setOpenConfirmation(false);
    setRefreshKey(oldKey => oldKey +1);
  };

  // *** DataGrid Column and Row formatting ***
  const orderColumns = [
    { field: 'id',
      headerName: 'ID',
      width: 90
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
    },
    {
      field: 'buyer',
      headerName: 'Buyer',
      sortable: false,
      renderCell: renderBuyerColumn,
      width: 120,
    },
    {
      field: 'seller',
      headerName: 'Seller',
      sortable: false,
      renderCell: renderSellerColumn,
      width: 120,
    },
    {
      field: 'sku',
      headerName: 'SKU',
      sortable: false,
      width: 110,
    },
    {
      field: 'size',
      headerName: 'Size',
      sortable: false,
      width: 80,
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: renderNameColumn,
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: renderStatusColumn,
      width: 200,
    },
    {
      field: 'authentication',
      headerName: 'Authentication',
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderAuthColumn,
      width: 130,
    },
    {
      field: 'track',
      headerName: 'Track',
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderTrackColumn,
      width: 70,
    },
    {
      field: 'tracking',
      headerName: 'Seller Tracking#',
      renderCell: renderSellerTrackingColumn,
      width: 180,
    },
    {
      field: 'priceNet',
      headerName: 'Price | Net',
      width: 150,
    },
    {
      field: 'moreOptions',
      headerName: 'Options',
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderOptionsColumns,
      width: 90,
    },
  ];

  const orderRows = (orderData) => {
    const orders = orderData.map((order) => {
      const data = order;

      const shippingLabel = data.shippingLabel ? data.shippingLabel.label_url : null;
      const sellerShippingLabel = data.seller_shippingLabel ? data.seller_shippingLabel.label_url : null;
      const trackingStatus = data.shippingLabel ? data.shippingLabel.tracking_url_provider : null;
      const sellerTrackingStatus = data.seller_shippingLabel ? data.seller_shippingLabel.tracking_url_provider : null;
      const trackingId = data.seller_shippingLabel ? data.seller_shippingLabel.tracking_number : null;

      return (
        {
          sku: data.productId,
          id: data.orderNumber,
          date: new Date(data.createdAt.seconds* 1000) ,
          product: data.product,
          buyer: data.buyer,
          seller: data.product.nickname,
          name: data.product.name,
          status: renderStatusColumn,
          tracking: renderSellerTrackingColumn,
          authentication: renderAuthColumn,
          priceNet: `$${data.product.price} | $${data.netProceeds} `,
          netProceeds: data.netProceeds,
          trackingId: trackingId,
          trackingStatus: trackingStatus,
          sellerTrackingStatus: sellerTrackingStatus,
          orderId: order.id,
          size: data.product.size,
          shippingLabel: shippingLabel,
          sellerShippingLabel: sellerShippingLabel,
          orderStatus: data.orderStatus,
          deadline: data.deadline,
          shippingFees: data.shippingFees,
          paymentMethod: data.paymentMethod.label,
          shippingAddress: data.shippingAddress,
          totalAmount: data.total_amount,
          sellerId: data.sellerId,
          paymentIntent: data.paymentIntent,
        }
      );
    });
    return orders;
  };

  /** firebase request to grab order data */
  const fetchOrders = async () => {
    const orders = await getOrders();
    orders.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

    setOrderData(orders);
  }

  useEffect(() => {
    fetchOrders();
  }, [refreshKey]);

  return (
    <div className={classes.container}>

      {/* Renders all Information */}
      <DataGrid
        columns={orderColumns}
        rows={orderRows(orderData)}
      />

      {/* Buyer Info Popover */}
      <Popover
        open={Boolean(buyerAnchorEl)}
        onClose={handleClose}
        anchorEl={buyerAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

      >
        <List>
          <ListItem>
            Payment Intent: {paymentIntent}
          </ListItem>

        </List>
      </Popover>

      {/* Seller Info Popover */}
      <Popover
        open={Boolean(sellerAnchorEl)}
        onClose={handleClose}
        anchorEl={sellerAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

      >
        <List>
          <ListItem>
            Paypal Acct: {paypalEmail}
          </ListItem>

        </List>
      </Popover>

      {/* Shoe Popover */}
      <Popover
        open={Boolean(shoeNameAnchorEl)}
        onClose={handleClose}
        anchorEl={shoeNameAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

      >
        <List>
          <ListItem>
            {shoeName}
          </ListItem>
          <img
            alt='shoe'
            src={shoeImageUrl}
          />
        </List>
      </Popover>

      {/* Extra Options Menu */}
      <Menu
        id='simple-menu'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
      >
        <MenuItem onClick={()=> handleResendEmailsClick()}>Resend Email</MenuItem>
      </Menu>

      {/* Authentication/Order Update Confirmation Modal */}
      <Modal
        open={openConfirmation}
        className={classes.modal}
        onClose={handleClose}
      >
        <div className={classes.paper}>
          <Typography>
            Are you sure you want to to update
          </Typography>
          <Typography>
            Order: {updateParams.id} to
          </Typography>
          <Typography style={{fontWeight: 'bold'}}>
            {updateParams.updatedStatus} ?
          </Typography>
          <Button onClick={()=> confirmUpdate(updateParams.orderId, updateParams.updatedStatus)}>
            Yes
          </Button>
          <Button onClick={() => setOpenConfirmation(false)}>
            No
          </Button>
        </div>
      </Modal>

      {/* Resend Email Modal */}
      <Modal
        open={openEmailModal}
        className={classes.modal}
        onClose={handleClose}
      >
        <div className={classes.paper}>
          <Typography>
            Resend email to:
          </Typography>
          <FormControl style={{minWidth: 120}}>
            <InputLabel id='simple-select-label'>
              Buyer/Seller
            </InputLabel>
            <Select
              labelId='simple-select-label'
              id='simple-select'
              value={buyerSeller}
              onChange={handleBuyerSellerChange}
            >
              <MenuItem value={'buyer'}>Buyer</MenuItem>
              <MenuItem value={'seller'}>Seller</MenuItem>
            </Select>
          </FormControl>

          <TextField
            id='outlined-basic'
            label='Email Address'
            variant='outlined'
            style={{margin: 20}}
            value={resendEmailAddress}
            onChange={handleResendEmailChange}
          />
          <ButtonGroup disableElevation onClick={handleClose}>
            <Button color='primary' variant='contained'onClick={handleResendSubmit}>
              Confirm
            </Button>
            <Button variant='contained'>
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </Modal>

    </div>
  );
};

export default AdminOrders;
