import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import CircularStd from '../assets/fonts/CircularStd/CircularStd-Book.ttf'
import CircularStdBold from '../assets/fonts/CircularStd/CircularStd-Bold.ttf';

const circularStd = {
  fontFamily: 'CircularStd',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
  url(${CircularStd}) format('truetype')
`,
};

const circularStdBold = {
  fontFamily: 'CircularStd',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${CircularStdBold}) format('truetype')
  `,
};

let theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 700,
      lg: 1439
    },
  },
  typography: {
    fontFamily: ['CircularStd'].join(','),
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h1: {
      fontWeight: 400,
      fontSize: '28px',
      lineHeight: 1.25,
    },
    h2: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: 1.2,
    },
    h3: {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: 1.5,
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: 1.5,
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.265,
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: 1.265,
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#0000FD',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    // text:{
    //   secondary: rgba(255, 255, 255, 0.7)
    // }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [circularStd, circularStdBold],
      },
    },
  },

  appbarHeight:{
    smDown: 48,
    mdUp: 80
  },
  containerWidth:{
    sm: 500,
    md: 992,
    lg: 1400
  }
});
theme = responsiveFontSizes(theme);

export default theme;
