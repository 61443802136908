import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: 'black',
    overflow: 'hidden',
  },
  toolbar: theme.mixins.toolbar,
}));
