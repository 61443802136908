import firebase from '../firebase';

export const follow = async (myuserId, sellerId) => {
  try {
    const currentTime = new Date(Date.now());
    await firebase
      .firestore()
      .collection('users')
      .doc(sellerId)
      .collection('followers')
      .doc(myuserId)
      .set({
        userId: myuserId,
        currentTime: currentTime,
      });
    await firebase
      .firestore()
      .collection('users')
      .doc(myuserId)
      .collection('following')
      .doc(sellerId)
      .set({
        userId: sellerId,
        currentTime: currentTime,
      });
    // success
    return (true);
  } catch (e) {
    // error
    return (Error('follow return ed'));
  }
};

export const unfollow = async (myuserId, sellerId) => {
  try {
    await firebase
      .firestore()
      .collection('users')
      .doc(sellerId)
      .collection('followers')
      .doc(myuserId)
      .delete();
    await firebase
      .firestore()
      .collection('users')
      .doc(myuserId)
      .collection('following')
      .doc(sellerId)
      .delete();
    // success
    return (true);
  } catch (e) {
    // error
    return (Error('unfollow return ed'));
  }
};

export const checkFollowing = async (myuserId, sellerId) => {
  try {
    const sellecDoc = await firebase
      .firestore()
      .collection('users')
      .doc(sellerId)
      .collection('followers')
      .doc(myuserId)
      .get();
    if (sellecDoc.exists) {
      return (true);
    } else {
      return (false);
    }
  } catch (e) {
    // error
    return (Error('follow return ed'));
  }
};