import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0
  },
  container: {
    padding: 0,
    width: 430,
    height: 430,
  },
  header: {
    fontWeight:700,
    fontSize:'28px',
    marginTop: 40,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  subText: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,

  },
  nicknameField: {
    marginBottom: 10,
    width: 200,
  },
  verifiedCheck:{
    fill: 'blue',
    position: 'absolute',
    top: 148,
    left: 80
  },
  sizeField: {
    marginTop: 20,
    marginBottom: 40,
    width: 200,

},
  closeButton: {
    color: theme.palette.grey[500],
  },
}));