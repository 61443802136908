import firebase from '../firebase';

/**
 * Opens shippo shipping label in a new window
 * @param {string} id - id for associated order
 */
export const openShippingLabelUrl = async (id) => {
  const order = await firebase
    .firestore()
    .collection('orders')
    .doc(id)
    .get()

  if (order.data().shippingLabel) {
    const labelUrl = order.data().shippingLabel.label_url
    window.open(labelUrl);
  }
};