import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: 40,
    margin: '40px 4%',
    minHeight: 800,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '16px'
    },
  },
  container: {
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1142px',
    display: 'flex',
  },
}));