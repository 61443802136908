import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  OutlinedInput,
  FormControl,
  InputAdornment,
  Avatar,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core/';
import SendIcon from '@material-ui/icons/Send';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: '#1A1A1A'
  },
  appBar2: {
    position: 'relative',
    background: 'white'
  },
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      minWidth: '960px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  img: {
    height: '500px'
  }
}));

export default function PostMediumCardMobileComments(props) {
  const classes = useStyles();
  const history = useHistory({forceRefresh:true});
  const { open, handleClose, comments, postData } = props;

  return (
    <Dialog PaperProps={{
      style: {
        // backgroundColor: 'black',
        boxShadow: 'none',
        height: '100%'
      },
    }} fullScreen open={open} onClose={handleClose}>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton edge='end' onClick={handleClose} aria-label='close'
            style={{ color: 'white', marginRight: '16px' }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography style={{ fontWeight: 600 }}> Comments </Typography>
        </Toolbar>
      </AppBar>
      <AppBar className={classes.appBar2} elevation={0}>
        <Toolbar style={{ }}>
          <FormControl style={{ width: '100%' }} className={clsx(classes.margin, classes.textField)} variant='outlined'>
            <OutlinedInput
              placeholder='Download the app to add comments'
              style={{ fontSize: '14px' }}
              type='text'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Toolbar>
      </AppBar>
      <Card style={{ height: '100%', flexGrow: 1, overflowY: 'auto' }}>
        <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <List dense={true}>
            <ListItem style={{ paddingLeft: 0, paddingRight: 0 }} alignItems='flex-start'>
              <ListItemAvatar>
                <Avatar alt={postData.nickname} src={postData.profileImg} style={{cursor:'pointer'}} onClick={()=>history.push(`/profile/${postData.nickname}`)}/>
              </ListItemAvatar>
              <ListItemText primary={postData.nickname}
                secondary={
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='body2' color='textSecondary' component='span'>
                      {postData.description}
                    </Typography>
                    <Typography variant='body2' color='textSecondary' component='span' style={{ fontSize: 10, marginTop: '8px' }}>
                      {moment.unix(postData.createdAt.seconds).fromNow()}
                    </Typography>
                  </span>
                }
              />
            </ListItem>
          </List>
        </CardContent>
        <CardContent style={{ paddingTop: 0}}>
          <Divider />
          <List dense={true} style={{ height: '100%' }}>
            {comments.map((comment, i) =>
              <ListItem key={comment.commentId + i} style={{ paddingLeft: 0, paddingRight: 0 }} alignItems='flex-start'>
                <ListItemAvatar>
                  <Avatar alt={comment.nickname} src={comment.profileImg} style={{cursor:'pointer'}} onClick={()=>history.push(`/profile/${comment.nickname}`)}/>
                </ListItemAvatar>
                <ListItemText primary={comment.nickname}
                  secondary={
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant='body2' color='textSecondary' component='span'>
                        {comment.message}
                      </Typography>
                      <Typography variant='body2' color='textSecondary' component='span' style={{ fontSize: 10, marginTop: '8px' }}>
                        {moment.unix(comment.createdAt.seconds).fromNow()}
                      </Typography>
                    </span>
                  }
                />
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
    </Dialog>
  );
}