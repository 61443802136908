import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    height: 66,
    margin: '0 4%',
    width: '100%',
    maxWidth: '1142px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: 'white',
    textTransform: 'none',
    height: 34,
    width: 135,
    padding: 0,
    '&:hover': { backgroundColor: '#E0E0E0' }
  }
}));