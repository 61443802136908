import React from 'react';
import { useStyles } from './SeeAll.style';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  // Button,
  // Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
// import {
//   SortBy,
// } from "react-instantsearch-dom";
import moment from 'moment';
// import { parse } from "query-string";

const SeeAll = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    data,
    title
  } = props;

  /** conditional rendering for navigation */
  let type;
  switch (title) {
    case 'Upcoming Drops':
      type = 'upcoming';
      break;
    case 'Listings':
      type = 'product';
      break;
    case 'Products':
      type = 'general';
      break;
    default:
      break;
  }

  const Card = ({ name, imageUrl, releaseDate, itemId, price}) => {
    return (
      <div className={classes.card}>
        <img
          src={imageUrl}
          alt='user'
          onClick={() => history.push(`/${type}/${itemId}`)}
          className={classes.cardImage}
        />
        <Typography variant='body2' className={classes.cardTitle}>
          {name}
        </Typography>
        {title === 'Upcoming Drops' &&
          <Typography variant='body2' style={{ fontWeight: 'bold', color: '#828282' }}>
            {moment(new Date(releaseDate.seconds * 1000)).format('MMMM DD')}
          </Typography>
        }
        {title === 'Listings' &&
          <Typography variant='body1' style={{ fontWeight: 'bold'}}>
            ${price}
          </Typography>
        }
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant='h1' className={classes.title}>
          {title}
        </Typography>
        <Grid
          spacing={matchesSmUp ? 2 : 1}
          container
          direction='row'
        >
          {data.map((item, i) => {
            return (
              <Grid
                className={classes.cardContainer}
                xs={6} sm={3}
                item
                key={i}
              >
                <Card
                  itemId={item.sku || item.id}
                  name={item.name}
                  key={i}
                  price={item.price}
                  imageUrl={item.grid_picture_url ? item.grid_picture_url : item.images[0]}
                  releaseDate={item.releaseDate ? item.releaseDate : null}
                />
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
        // <Toolbar variant="dense">
        //   <Button onClick={() => setOpenFilters(true)}>
        //     <Typography style={{ fontWeight: 600 }}>Filter</Typography>
        //   </Button>
        //   <div style={{ flexGrow: 1 }}></div>
        //   <span style={{ flexDirection: 'row', display: 'flex' }} >
        //     <Typography style={{ fontWeight: 600 }}>Sort by: </Typography>
        //     <div>&nbsp;</div>
        //     <SortBy
        //       //PRODUCTION
        //       items={[
        //         { value: "Products_Shoes", label: "Featured" },
        //         { value: "Products_Shoes_Price_Asc", label: "Price Asc" },
        //         { value: "Products_Shoes_Price_Desc", label: "Price Desc" },
        //       ]}
        //       defaultRefinement={"Products_Shoes"}
        //     //DEVELOPMENT
        //     // items={[
        //     //   { value: "Products_Shoes_DEV", label: "Featured" },
        //     //   { value: "Products_Shoes_Price_Asc_DEV", label: "Price Asc" },
        //     //   { value: "Product_Shoes_Price_Desc", label: "Price Desc" },
        //     // ]}
        //     // defaultRefinement={"Products_Shoes_DEV"}
        //     // transformItems={}
        //     />
        //   </span >
        // </Toolbar>
  );
};

export default SeeAll;
