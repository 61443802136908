import firebase from '../firebase';

export const getRaffles = async (limit) => {
  try {
    const rafflesDocQuery = await firebase
      .firestore()
      .collection('raffles')
      .orderBy('createdTime', 'desc')
      .limit(limit)
      .get();

    const docs = rafflesDocQuery.docs;
    return (docs);
  } catch (e) {
    return (e);
  }
};
