import {
  UPDATE_PENDING_SIGNUP_INFO,
  CLEAR_SIGNUP_INFO,
} from './actions';

const initialState ={};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PENDING_SIGNUP_INFO:
      return {...state,
        email: action.value.email,
        nickname: action.value.nickname,
        size: action.value.size,
        password: action.value.password,
        uid: action.value.uid,
      };

    case CLEAR_SIGNUP_INFO:
      return {...state, signUp: {}};

    default:
      return state;
  }
}