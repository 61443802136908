import React, { useEffect, useState } from 'react';
import { useStyles } from './Home.style';
import { CircularProgress } from '@material-ui/core';

import ProductCarousel from '../../components/Home/ProductCarousel';
import UserCarousel from '../../components/Home/UserCarousel';
import UpcomingCarousel from '../../components/Home/UpcomingCarousel/UpcomingCarousel';
import { getPopularProducts } from '../../utils/HomePage/getPopularProducts';
import { getRecentProducts } from '../../utils/HomePage/getRecentProducts';
import getFeaturedUsers from '../../utils/HomePage/getFeaturedUsers';
import getUpcomingProducts from '../../utils/HomePage/getUpcomingProducts';
import DownloadBanner from '../../components/Home/DownloadBanner';

const Home = () =>  {
  const classes = useStyles();
  const [popularProducts, setPopularProducts] = useState([]);
  const [recentProducts, setRecentProducts] = useState([]);
  const [featuredUsers, setFeaturedUsers] = useState([]);
  const [upcomingData, setUpcomingData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAllData = async () => {
    const productsData = await getPopularProducts(20);
    setPopularProducts(productsData.productsDocs);

    const usersData = await getFeaturedUsers(20);
    setFeaturedUsers(usersData);

    const recentData = await getRecentProducts(20);
    setRecentProducts(recentData.productsDocs);

    const upcomingData = await getUpcomingProducts(20);
    setUpcomingData(upcomingData);

    setLoading(false);
  };



  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllData();
  }, []);

  return (
    <React.Fragment>
      <DownloadBanner/>
      <div className={classes.root}>
      { loading ?
        <CircularProgress/> :
        <div className={classes.container}>
          <ProductCarousel
            title={'Popular'}
            data={popularProducts}
          />
          <ProductCarousel
            title={'Recently Posted'}
            data={recentProducts}
          />
          <UpcomingCarousel
            title={'Upcoming Drops'}
            data={upcomingData}
          />
          <UserCarousel
            title={'Featured Members'}
            data={featuredUsers}
          />
        </div>
      }
      </div>
    </React.Fragment>
  );
}

export default Home;

