import { combineReducers } from 'redux';
import reducer from './reducer';
import { signUp } from '../entities/signUp';
import { modals } from '../entities/modals';

export default combineReducers({
  reducer,
  signUp,
  modals,
});
