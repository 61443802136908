import firebase from './firebase';

const fetchGeneralDetails = async (productID) => {
  try {
    let productDoc = await firebase
      .firestore()
      .collection('general_products_data')
      .doc(productID)
      .get();
    return (productDoc.data());
  } catch (e) {
    return (e);
  }
};

export default fetchGeneralDetails;