import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

// eslint-disable-next-line
var firebaseProductionConfig = {
  apiKey: 'AIzaSyAf64aUc5t4z65DEuFJyneiKNft4om4ge0',
  authDomain: 'glowpop-b11fc.firebaseapp.com',
  databaseURL: 'https://glowpop-b11fc.firebaseio.com',
  projectId: 'glowpop-b11fc',
  storageBucket: 'glowpop-b11fc.appspot.com',
  messagingSenderId: '270707704816',
  appId: '1:270707704816:web:ec76a910d9c936b47e9335',
  measurementId: 'G-ET3LWE1XDE',
};

// eslint-disable-next-line
var firebaseDevelopmentConfig = {
  apiKey: 'AIzaSyCksztsMI7SukG1U9R3XjQm6HKLRdcQafQ',
  authDomain: 'sneakerlist-dev.firebaseapp.com',
  databaseURL: 'https://sneakerlist-dev.firebaseio.com',
  projectId: 'sneakerlist-dev',
  storageBucket: 'sneakerlist-dev.appspot.com',
  messagingSenderId: '1052571002267',
  appId: '1:1052571002267:web:94dec6c3d6aafbba61ad3e',
  measurementId: 'G-W5SVTVZRKJ',
};

// Initialize Firebase
firebase.initializeApp(firebaseProductionConfig);
firebase.analytics();

export default firebase;
