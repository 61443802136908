import React, { useEffect, useState } from 'react';
import { useStyles } from './UpcomingProduct.style';
import { useParams } from 'react-router-dom';
import getUpcomingProducts from '../../utils/HomePage/getUpcomingProducts';
import UpcomingProductInfo from '../../components/UpcomingProducts/UpcomingProductInfo';
import MoreUpcomingProducts from '../../components/UpcomingProducts/MoreUpcomingProducts';

const UpcomingProduct = () => {
  const { productID } = useParams();
  const classes = useStyles();
  const [ otherReleases, setOtherReleases ] = useState([]);
  const [ releaseProduct, setReleaseProduct] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      /** fetches upcoming products and then separates out product to be viewed
       * while the rest are assigned to array to be shwon for Other Release
       */
      let upcomingData = await getUpcomingProducts(13);
      if (upcomingData) {
        let otherData = [];
        let releaseData = {};

        upcomingData.forEach(x => {
          if (x.sku === productID){
            Object.assign(releaseData, x);
          } else if (otherData.length !== 12 ) {
            otherData.push(x)
          }
        });
        setOtherReleases(otherData);
        setReleaseProduct(releaseData);
      }
    })();
  }, []);

  return (
    <div className={classes.container} >
      <div className={classes.subContainer} >
        <UpcomingProductInfo data={releaseProduct}/>

        <MoreUpcomingProducts
          upcomingData={otherReleases}
        />
      </div>
    </div>
  );
};

export default UpcomingProduct;