import firebase from '../firebase';

const getUpcomingProducts = async (limit) => {
  const currentTime = new Date(Date.now());

  const releaseDoc = await firebase
    .firestore()
    .collection('releases')
    .orderBy('releaseDate')
    .startAfter(currentTime)
    .limit(limit)
    .get();

  const releases = releaseDoc.docs.map((item) => {
    return item.data();
  });

  return releases;
};

export default getUpcomingProducts;
