import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
    paddingTop: 'min(4%, 40px)',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    columnGap: '16px',
  },
  imageContainer: {
    flex: 1,
    flexWrap: 'wrap',
    minWidth: 242,
    maxWidth: 464,
  },
  informationContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
    flexWrap: 'wrap',
    minWidth: 242,
    maxWidth: 464,
    justifyContent: 'center'
  },
  name: {
    fontWeight: 'bold',
    marginBottom: 24,
    marginTop: 14,
  },
  date: {
    fontWeight: 'bold',
  },
  description: {
    marginTop: 40,
    [theme.breakpoints.up('lg')]: {
      width: 756,
    },
    [theme.breakpoints.down('md')]: {
      width: 628,
    },
    [theme.breakpoints.down('sm')]: {
      width: 500,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '16px',
    },
    display: 'flex',
  },
}));