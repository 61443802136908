import React, { useState, useEffect } from 'react';
import { useStyles } from '../Feed/Feed.style';
import {
  Container,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { connect } from 'react-redux';
import firebase from '../../utils/firebase';
import PostMediumCard from '../../components/Post/PostMediumCard'
import PostModal from '../../components/Post/PostModal'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

function Feed(props) {
  const classes = useStyles();
  const { userId } = props
  const [posts, setPosts] = useState([]);
  const [lastVisiblePost, setLastVisiblePost] = useState(null);
  const [noMorePosts, setNoMorePosts] = useState(false);

  /* Fetching Posts */
  useEffect(() => {
    fetchPosts()
  }, []);

  const fetchPosts = async () => {
    const postDocQuery = await firebase
      .firestore()
      .collection('posts')
      .orderBy('createdAt', 'desc')
      .limit(5)
      .get();
    const postsDoc = postDocQuery.docs;
    const lastVisiblePostTemp = postsDoc[postsDoc.length - 1];

    if (postsDoc.length > 0) {
      const postsData = postsDoc.map(item => {
        let itemTemp = item.data()
        itemTemp['postId'] = item.id
        itemTemp['liked'] = itemTemp.likedBy && itemTemp.likedBy.includes(userId) ? true : false
        return itemTemp
      })
      setPosts(postsData)
      setLastVisiblePost(lastVisiblePostTemp)
      // console.log("Feed Posts: ", postsData)
    } else {
      setNoMorePosts(true)

    }
  }

  const fetchAdditionalPosts = async () => {
    const postDocQuery = await firebase
      .firestore()
      .collection('posts')
      .orderBy('createdAt', 'desc')
      .startAfter(lastVisiblePost)
      .limit(5)
      .get();

    const postsDoc = postDocQuery.docs;
    const lastVisiblePostTemp = postsDoc[postsDoc.length - 1];

    if (postsDoc.length > 0) {
      const postsData = postsDoc.map(item => {
        let itemTemp = item.data()
        itemTemp['postId'] = item.id
        itemTemp['liked'] = itemTemp.likedBy && itemTemp.likedBy.includes(userId) ? true : false
        return itemTemp
      })
      let newPostsData = [...posts, ...postsData];
      setPosts(newPostsData)
      setLastVisiblePost(lastVisiblePostTemp)
      setLoadMore(false)
    } else {
      setNoMorePosts(true)
    }
  }

  /* onScrollBottom listener + load more*/
  const [loadMore, setLoadMore] = useState(false);
  useBottomScrollListener(() => onScrollBottom(), {
    offset: 200,
  });
  const onScrollBottom = () => {
    if (!loadMore && !noMorePosts) {
      setLoadMore(true)
      fetchAdditionalPosts()
    }
  }

  /* Post Modal*/
  const [openPostModal, setOpenPostModal] = useState(false);
  const [postModalData, setPostModalData] = useState(null);
  const handleClickOpenPostModal = (postData) => {
    setPostModalData(postData)
    setOpenPostModal(true);
  };
  const handleClosePostModal = () => {
    setOpenPostModal(false);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.containerFeed} maxWidth='md'>
        <Grid
          className={classes.container}
          container
          spacing={0}
          justify='center'
          direction='row'
          alignItems='stretch'>
          {posts.map((post, i) => {
            return (
              <Grid xs={12} sm={8} item key={post.postId + i} style={{ paddingBottom: '48px' }} >
                <PostMediumCard post={post} postsAll={posts} setPosts={setPosts} style={{ height: '100%' }} handleClickOpenPostModal={handleClickOpenPostModal} />
              </Grid>)
          })}
          {loadMore && !noMorePosts ?
            <Grid xs={12} sm={8} item style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <CircularProgress />
            </Grid> :
            <div></div>}
          <PostModal open={openPostModal} handleClose={handleClosePostModal} postData={postModalData} postsAll={posts} setPosts={setPosts} />
        </Grid>
      </Container>
    </div>
  );
}

const mapStateToProps = state => {
  const { userId } = state.reducer;
  return { userId };
};

export default connect(mapStateToProps)(Feed);

