import firebase from '../firebase';

const getPopularProducts = async (limit) => {
  const generalProducts = await firebase
    .firestore()
    .collection('general_products_data')
    .orderBy('viewCount', 'desc')
    .orderBy('grid_picture_url', 'asc')
    .limit(limit)
    .get();

  let lastItem = '';

  const productsDocs = generalProducts.docs.map(el => {
    lastItem = el.data().viewCount;
    return el.data()
  });

  return {productsDocs, lastItem};
};

const getMorePopularProducts = async (limit, lastLoaded) => {
  const generalProducts = await firebase
    .firestore()
    .collection('general_products_data')
    .orderBy('viewCount', 'desc')
    .orderBy('grid_picture_url', 'asc')
    .startAfter(lastLoaded)
    .limit(limit)
    .get();

  let lastItem = '';

  const productsDocs = generalProducts.docs.map(el => {
    lastItem = el.data().viewCount;
    return el.data()
  });

  return {productsDocs, lastItem};
};

export {getPopularProducts, getMorePopularProducts};
