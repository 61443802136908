import React, { useRef } from 'react';
import { useStyles } from './ProductCarousel.style';
import { useHistory } from 'react-router-dom';
import {
  useTheme,
  Typography,
  IconButton,
  useMediaQuery,
  Grid
} from '@material-ui/core';
import moment from 'moment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const ProductCarousel = (props) => {
  const { title, data } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  /** conditional rendering for navigation */
    let type;
    switch (title) {
      case 'Recently Posted':
        type = 'product';
        break;
      case 'Popular':
        type = 'general';
        break;
      default:
        break;
    }

  /** Individual card element for carousel */
  const ShoeCard = ({ name, imageUrl, price, releaseDate, itemId}) => {
    return (
      <div className={classes.card}>
        <img
          src={imageUrl}
          alt='user'
          onClick={() => {history.push(`/${type}/${itemId}`)}}
          className={classes.cardImage}
        />
        <Typography variant='body2' style={{ color: '#333333'}}>
          {name}
        </Typography>
        {title === 'Recently Posted' && <Typography variant='h3' style={{ fontWeight: 'bold', color: '#333333' }}>
          ${price}
        </Typography>}
        {title === 'Upcoming Drops' && <Typography variant='body2' style={{ fontWeight: 'bold', color: '#828282' }}>
          {moment(new Date(releaseDate.seconds * 1000)).format('MMMM DD')}
        </Typography>}
      </div>
    )
  }

  const scrollEl = useRef(null);

  /** scrollOffset: changes distance traveled */
  const scrollLeft = (scrollOffset) => {
    /** x: changes speed and distance */
    const x = 6;
    for (let i=0; i>scrollOffset; i--) {
      setTimeout(() => (scrollEl.current.scrollLeft -= x), Math.abs(i) * 2);
    }
  };

  /** scrollOffset: changes distance traveled */
  const scrollRight = (scrollOffset) => {
    /** x: changes speed and distance */
    const x = 8;
    for (let i=0; i<scrollOffset; i++) {
      setTimeout(() => (scrollEl.current.scrollLeft += x), i * 2);
    }
  };

  const handleLeftClick = () => {
    scrollLeft(-80);
  };
  const handleRightClick = () => {
    scrollRight(80);
  };

  const handleSeeAllClick = () => {
    history.push({pathname: `/${type}/all`});
  };

  return (
    <div className={classes.container} >
      <div
        style={{
          overflowX: 'hidden',
        }}
      >
        <Grid container justify='space-between'>
          <Typography display='inline' variant='h2' style={{fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Typography
            onClick={handleSeeAllClick}
            display='inline'
            variant='body2'
            style={{ cursor: 'pointer', color: '#0000FD', textAlign: 'right'
          }}>
            View More
          </Typography>
        </Grid>
        <div className={classes.itemsContainer} ref={scrollEl}>
          {data.map((item, i) => (
            <ShoeCard
              itemId={item.sku || item.id}
              name={item.name}
              key={i}
              imageUrl={item.grid_picture_url ? item.grid_picture_url : item.images[0]}
              price={item.price}
              releaseDate={item.releaseDate ? item.releaseDate : null}
            />
          ))}
        </div>
      </div>
      {useMediaQuery(theme.breakpoints.up('md')) &&
        <IconButton variant='contained' className={classes.leftArrowButton} onClick={handleLeftClick}>
          <ChevronLeftIcon className={classes.leftArrow} />
        </IconButton>
      }
      {useMediaQuery(theme.breakpoints.up('md')) &&
        <IconButton variant='contained' className={classes.rightArrowButton} onClick={handleRightClick}>
          <ChevronRightIcon className={classes.rightArrow} />
        </IconButton>
      }
    </div>
  );
};

export default ProductCarousel;