import firebase from '../firebase';

export const getUserDoc = async (userId) => {
  try {
    const userDoc = await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .get()

    // success
    if (userDoc.exists) {
      return (userDoc.data());
    }
  } catch (e) {
    // error
    return (e);
  }
};

export const getUserPrivateDoc = async (userId) => {
  try {
    const userPrivDoc = await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .collection('private')
      .doc('doc')
      .get()

    // success
    if (userPrivDoc.exists) {
      return (userPrivDoc.data());
    }
  } catch (e) {
    // error
    return (e);
  }
};

export const checkNickname = async (nickname) => {
  try {
    const searchDoc = await firebase
      .firestore()
      .collection('users')
      .where('nickname', '==', nickname)
      .limit(1)
      .get();

    const nicknameCheck = searchDoc.docs;

    if (nicknameCheck.length === 0) {
      return (true)
    } else {
      return (false)
    }
  } catch (e) {
    return (e);
  }
};

export const updateProfile = async (userId, updateData) => {
  try {
    const {
      nickname,
      about,
      profileImg,
      firstName,
      lastName
    } = updateData
    const userDoc = await firebase.firestore().collection('users').doc(userId);
    await userDoc.update({
      nickname,
      about,
      profileImg,
    })
    const userPrivDoc = await userDoc.collection('private').doc('doc');
    userPrivDoc.update({
      firstName: firstName,
      lastName: lastName,
    })
    return (true)
  } catch (e) {
    return (e);
  }
};

