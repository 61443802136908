import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: 'black',
  },
  logoImage: {
    height:37,
    width: 350,
    objectFit: 'cover',
    marginBottom: 34,
    marginTop: 50,
  },
  logoImageSm: {
    height:28,
    width: 250,
    objectFit: 'cover',
    marginBottom: 34,
    marginTop: 50,
  },
  appButton: {
    marginBottom: 18,
  },
  linksContainer: {
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      display: 'flex',
    },
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 16,
    color: '#E6E6E6',
  },
  linkText: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    fontSize: 16
  },
  copyRightContainer:{
    display: 'flex',
    marginBottom: 16,
    marginTop: 24,
  },
  copyrightText: {
    fontSize: 12,
    color: '#999999',
  },
}));