export const resendBuyerEmail = async (params, email) => {
  // const API_URL ='https://us-central1-glowpop-b11fc.cloudfunctions.net/resendBuyerEmail';
  const API_URL ='http://localhost:5002/glowpop-b11fc/us-central1/resendBuyerEmail';

  console.log(params)
  const response = await fetch(`${API_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      email,
      // product: JSON.stringify(params.product),
      orderNumber: params.id,
      orderStatus: params.orderStatus,
      shippingFees: params.shippingFees,
      // paymentMethod: params.paymentMethod,
      // shippingAddress: params.shippingAddress,
      // total_amount: params.totalAmount,
      // trackingLink: params.trackingStatus,
    },
  });
  await console.log(response.json());
};