import React, { useState, useEffect } from 'react';
import { useStyles } from './FinishSignUpModal.style';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  TextField,
  Button,
  Dialog,
  Container,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  FormControl
} from '@material-ui/core';
import { setOpenFinishSignUpModal } from '../../../redux/entities/modals/actions';
import firesStoreCreateUser from '../../../utils/firestoreCreateUser';
import { UPDATE_PENDING_SIGNUP_INFO, CLEAR_SIGNUP_INFO } from '../../../redux/entities/signUp/actions';
import firebase from '../../../utils/firebase';
import { sizeData } from '../../../constants/sizes';
import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from 'react-router-dom';


export default function FinishSignUpModal() {
  const selector = (state) => state
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const openFinishSignUpModal = useSelector(selector).modals.openFinishSignUpModal
  const [nickname, setNickname] = useState('');
  const [verified, setVerified] = useState(false);
  const [size, setSize] = useState('');
  const signUpInfo = useSelector(selector).signUp;

  const verifyNickname = async () => {
    try {
      const searchDoc = await firebase
        .firestore()
        .collection('users')
        .where('nickname', '==', nickname)
        .limit(1)
        .get();

      const nicknameCheck = searchDoc.docs;

      if (nicknameCheck.length === 0) {
        setVerified(true);
      }
    } catch (error) {
      alert(error.toString());
    }
  };

  const handleNicknameChange = (event) => {
    setVerified(false)
    setNickname(event.target.value);
    verifyNickname();
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleSubmit = () => {
    dispatch({
      type: UPDATE_PENDING_SIGNUP_INFO,
      value: {
        ...signUpInfo,
        nickname: nickname,
        size: size,
      }
    });
  };

  const createUserInFirestore = async () => {
    try {
      let userData;
      if (signUpInfo.password) {
        // for normal sign ups
        userData = await firebase
          .auth()
          .createUserWithEmailAndPassword(signUpInfo.email.trim(), signUpInfo.password);
      } else {
        // for social auth without password
        userData = { user: {} };
        userData.user.email = signUpInfo.email;
        userData.user.photoURL = signUpInfo.photoURL;
        userData.user.uid = signUpInfo.uid;
      }
      userData.user.nickname = nickname;
      userData.user.size = size;
      firesStoreCreateUser(userData.user);
      alert('signup sucessful');
      history.push('/loading')
    } catch (e) {
      if (e.code === 'auth/email-already-in-use') {
        alert(
          'An account already exists for the provided email. Please use another email.'
        );
      } else {
        // console.log("createUserInFirestore: ", e);
        alert(e);
      }
      return;
    }
  };

  const handleClose = () => {
    dispatch({ type: setOpenFinishSignUpModal, value: false });
    dispatch({ type: CLEAR_SIGNUP_INFO });
    setNickname('');
    setSize('');
    setVerified(false);
  }

  useEffect(() => {
    if (signUpInfo.nickname) {
      createUserInFirestore();
      dispatch({ type: setOpenFinishSignUpModal, value: false });
    }
  }, [signUpInfo])

  return (
    <Dialog
      className={classes.root}
      onClose={handleClose}
      open={openFinishSignUpModal}
    >
      <Container className={classes.container}>
        <Typography
          className={classes.header}
        >
          Finish Sign Up
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
          className={classes.subText}
        >
          Enter a valid nickname and then select your shoe size
        </Typography>

        <Grid container style={{}}
          direction='column'
          justify='center'
          alignItems='center'
        >
          {verified && <CheckIcon className={classes.verifiedCheck} />}
          <TextField
            required
            id='standard-required'
            label='Enter Nickname'
            value={nickname}
            inputProps={{ maxLength: 17 }}
            onChange={handleNicknameChange}
            className={classes.nicknameField}
          />
          <FormControl>
            <InputLabel id='simple-select-label'>Select Shoe Size</InputLabel>
            <Select
              required
              className={classes.sizeField}
              // labelId="simple-select-label"
              // id="simple-select"
              value={size}
              onChange={handleSizeChange}
            >
              {sizeData.map(x => (
                <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            disabled={!verified || !size}
            style={{ width: 160, textTransform: 'none', fontWeight: 700 }}
          >
            Sign Up
          </Button>
          <Button
            onClick={() => dispatch({ type: setOpenFinishSignUpModal, value: false })}
            style={{ textTransform: 'none', fontWeight: 700 }}
            color='primary'
          >
            Cancel
          </Button>
        </Grid>
      </Container>
    </Dialog>
  );
}
