import React, { useState } from 'react';
import { useStyles } from './TopAppBar.style';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, Toolbar, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Index } from 'react-instantsearch-dom';
import LoggedInAvatar from './LoggedInAvatar';
import AutoComplete from './AutoComplete';
import LoginSignUpButtons from './LoginSignUpButtons';
import MobileMenu from './MobileMenu';
import logo from '../../assets/sneakerlist_logo/sneakerlist_nobg_blk.svg';

function TopBar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory({ forceRefresh: true });
  const { loginStatus, userId, userProfile } = props;
  const [mobileSearchMode, setMobileSearchMode] = useState(false);

  const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar className={classes.toolbar} >
          <div className={classes.logoContainer}>
            <img
              src={logo}
              alt='logo'
              className={classes.logo}
              onClick={() => history.push('/')}
            />
          </div>
          <AutoComplete
            mobileSearchMode={mobileSearchMode}
            setMobileSearchMode={setMobileSearchMode}
          />
          <Index indexName='Users_Shoes' />
          <Index indexName='Products_Shoes' />
          <div className={classes.menuContainer}>
            {matchesMdDown && !loginStatus &&
              <MobileMenu />
            }
            {!matchesSmDown && !loginStatus &&
              <LoginSignUpButtons/>
            }
            {loginStatus &&
              <LoggedInAvatar
                userId={userId}
                userProfile={userProfile}
              />
            }
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { loginStatus, userId, userProfile } = state.reducer;

  return { loginStatus, userId, userProfile };
};

export default connect(mapStateToProps)(TopBar);

