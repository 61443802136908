import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      minHeight: `calc(100vh - ${theme.appbarHeight.smDown}px - 48px)`,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: `calc(100vh - ${theme.appbarHeight.mdUp}px - 48px)`,
    },
  },
  input: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));