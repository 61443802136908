import React, { useState } from 'react';
import { useStyles } from '../Contact/Contact.style';
import {
  Container,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
// import WhiteLogo from "../assets/sneakerlist_logo/sneakerlist_nobg_wt.svg";

export default function Contact() {
  const classes = useStyles();
  const [emailMessage, setEmailMessage] = useState('');

  const handleEmailMessageChange = e => {
    setEmailMessage(e.target.value);
  };

  return (
    <Container disableGutters maxWidth='xl' className={classes.root}>
      <div style={{ display: 'flex' }} className={classes.toolbar} />
      <Container
        disableGutters
        style={{
          display: 'flex',
          border: '1px solid blue',
        }}
        maxWidth='lg'
      >
        <Grid
          style={{ height: '100%', border: '1px solid white' }}
          justify='flex-end'
          alignItems='center'
          container
        >
          <Grid
            item
            justify='flex-end'
            style={{
              border: '1px solid coral',
              flex: 1,
              backgroundColor: '#808080',
            }}
          >
            <form style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography>Contact Us</Typography>
              <TextField
                style={{ color: 'white' }}
                id='standard-multiline-flexible'
                label='Multiline'
                multiline
                rowsMax={4}
                value={emailMessage}
                onChange={handleEmailMessageChange}
              />
            </form>
          </Grid>
          <Grid
            item
            justify='flex-end'
            style={{
              border: '1px solid coral',
              flex: 1,
              backgroundColor: '#808080',
            }}
          >
            <form style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography>Contact Us</Typography>
              <TextField
                style={{ color: 'white' }}
                id='standard-multiline-flexible'
                label='Multiline'
                multiline
                rowsMax={4}
                value={emailMessage}
                onChange={handleEmailMessageChange}
              />
            </form>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

