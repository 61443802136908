import React from 'react';
import {
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const ProfileTabs = (props) => {
  const { tab, handleChangeTab } = props;
  const classes = useStyles();

  const a11yProps = (index) => {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  return (
    <AppBar position='static' elevation={0} style={{ backgroundColor: 'white' }} >
      <Tabs
        style={{ marginBottom: '8px', borderBottom: '1px solid #E0E0E0' }}
        value={tab}
        onChange={handleChangeTab}
        // scrollButtons="on"
        // indicatorColor="secondary.dark"
        textColor='primary'
        aria-label='tabs between sneakers and feed'
        centered

        variant='fullWidth'
      >
        <Tab style={{ textTransform: 'none' }} label='Shoes' className={tab === 0 && classes.customTabs} {...a11yProps(0)} />
        <Tab style={{ textTransform: 'none' }} label='Stats' className={tab === 1 && classes.customTabs} {...a11yProps(1)} />
      </Tabs>
    </AppBar>
  );
};

export default ProfileTabs;

const useStyles = makeStyles(() => ({
  customTabs: {
    fontWeight: 'bold',
  }
}));