import React, { useState, useEffect } from 'react';
import firebase from '../../utils/firebase';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  IconButton,
  // Chip,
  useMediaQuery
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { setOpenDownloadAppModal } from '../../redux/entities/modals/actions'
import moment from 'moment';
import { postLike, postUnlike } from '../../utils/firebaseFunctions/post';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PostMediumCardMobileChats from './PostMediumCardMobileComments'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    flexGrow: 1
  }
}));

function PostMediumCard(props) {
  const history = useHistory({ forceRefresh: true });
  const classes = useStyles();
  const theme = useTheme()
  const dispatch = useDispatch();
  const matchesSmDownBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const { loginStatus, post, postsAll, setPosts, handleClickOpenPostModal, userId } = props
  const [comments, setComments] = useState([]);
  const [commentsPreview, setCommentsPreview] = useState([]);
  const [reload, setReload] = useState(false);
  const [openCommentsModal, setOpenCommentsModal] = useState(false);

  const handleOpenCommentsModal = () => {
    setOpenCommentsModal(true);
  };
  const handleCloseCommentsModal = () => {
    setOpenCommentsModal(false);
  };

  useEffect(() => {
    if (post) {
      fetchComments()
    }
  }, [post]);

  const fetchComments = async () => {
    const postCommentsQuery = await firebase
      .firestore()
      .collection('posts')
      .doc(post.postId)
      .collection('comments')
      .orderBy('createdAt', 'asc')
      // .limit(2)
      .get();

    const commentsDocs = postCommentsQuery.docs;

    if (commentsDocs.length > 0) {
      const commentsData = commentsDocs.map(doc => {
        let temp = doc.data()
        temp.commentId = doc.id
        return temp
      })
      getUserMetaDataForComments(commentsData, commentsWithUserData => {
        setComments(commentsWithUserData)
        if (commentsWithUserData.length > 2) {
          const one = commentsWithUserData[commentsWithUserData.length - 1]
          const two = commentsWithUserData[commentsWithUserData.length - 2]
          const temp = []
          temp.push(two)
          temp.push(one)
          setCommentsPreview(temp)
        } else {
          setCommentsPreview(commentsWithUserData)
        }
      });
    }
  }

  const getUserMetaDataForComments = (commentsData, callback) => {
    let requests = commentsData.map(async (commentData) => {
      const userId = commentData.userId;
      const userDoc = firebase.firestore().collection('users').doc(userId);
      const userData = await userDoc.get();
      const { nickname, profileImg } = userData.data();
      commentData.nickname = nickname
      commentData.profileImg = profileImg
      return commentData
    })
    Promise.all(requests)
      .then(commentsWithUserData => {
        callback(commentsWithUserData);
      })
      .catch(error => console.log(error))
  }

  const handleLike = async () => {
    try {
      if (!post.liked) {
        const result = await postLike(userId, post.postId)
        if (result) {
          setPosts(
            postsAll.map((item) => {
              if (item.postId === post.postId) {
                item.likesCount += 1
                item.liked = true
              }
              return item
            })
          )
        }
      } else {
        const result = await postUnlike(userId, post.postId)
        if (result) {
          setPosts(
            postsAll.map((item) => {
              if (item.postId === post.postId) {
                item.likesCount -= 1
                item.liked = false
              }
              return item
            })
          )
        }
      }
      setReload(!reload)
    } catch (e) {
      console.error('[handleLike] error', e);
    }
  }

  const handleOpenComments = () => {
    if (matchesSmDownBreakpoint) {
      handleOpenCommentsModal()
    } else {
      handleClickOpenPostModal(post)
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar src={post.profileImg} alt={post.nickname} style={{ cursor: 'pointer' }} onClick={() => history.push(`/profile/${post.nickname}`)} />
        }
        action={
          <IconButton aria-label='settings'>
            <MoreVertIcon />
          </IconButton>
        }
        title={post.nickname}
      />

      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <CardMedia
          component='img'
          src={post.images[0]}
          style={{ height: '434px', }}
        />
      </CardContent>

      <CardActions disableSpacing style={{ paddingBottom: 0 }}>
        {loginStatus ?
          <IconButton aria-label='add to favorites' onClick={() => handleLike()}>
            {post.liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
          :
          <IconButton aria-label='add to favorites' onClick={() => dispatch({ type: setOpenDownloadAppModal, value: true })}>
            <FavoriteBorderIcon />
          </IconButton>
        }

        <IconButton aria-label='add to favorites' onClick={() => handleOpenComments()}>
          <ChatBubbleOutlineIcon />
        </IconButton>
        <div style={{ flexGrow: 1 }}></div>
        {/* <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: '8px' }} label={post.type} /> */}
      </CardActions>

      <CardActions disableSpacing style={{ paddingTop: 0 }} >
        <Typography style={{ paddingLeft: '12px' }} variant='body2' color='textSecondary' component='p'>
          {`${post.likesCount} Likes`}
        </Typography>
      </CardActions>

      <CardActions disableSpacing style={{ paddingTop: 0 }} >
        <Typography style={{ paddingLeft: '12px', fontWeight: '600' }} variant='body2' color='textPrimary' component='span'>
          {`${post.nickname}`}
          <Typography variant='body2' color='textPrimary' component='span'>
            {` ${post.description}`}
          </Typography>
        </Typography>
      </CardActions>

      {post.commentsCount > 0 ?
        <CardActions disableSpacing style={{ paddingTop: 0 }} >
          <Typography onClick={() => handleOpenComments()} style={{ paddingLeft: '12px', cursor: 'pointer' }} variant='body2' color='textSecondary' component='p'>
            {`View all ${post.commentsCount} comments`}
          </Typography>
        </CardActions> : <div></div>}

      <CardActions disableSpacing style={{ paddingTop: 0, flexDirection: 'column', alignItems: 'flex-start' }} >
        {commentsPreview.map((comment, i) =>
          <Typography key={comment.commentId + i} style={{ paddingLeft: '12px', fontWeight: '600' }} variant='body2' color='textPrimary' component='span'>
            {`${comment.nickname} `}
            <Typography variant='body2' color='textPrimary' component='span'>
              {comment.message}
            </Typography>
          </Typography>
        )}
      </CardActions>

      <CardActions disableSpacing style={{ paddingTop: 0 }} >
        <Typography style={{ paddingLeft: '12px' }} variant='body2' color='textSecondary' component='p'>
          {moment.unix(post.createdAt.seconds).fromNow()}
        </Typography>
      </CardActions>

      <PostMediumCardMobileChats
        open={openCommentsModal}
        handleClose={handleCloseCommentsModal}
        comments={comments}
        postData={post}
      />
    </Card>
  );
}

const mapStateToProps = state => {
  const { userId, loginStatus } = state.reducer;
  return { userId, loginStatus };
};

export default connect(mapStateToProps)(PostMediumCard);
