import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: 756,
    },
    [theme.breakpoints.down('md')]: {
      width: 628,
    },
    [theme.breakpoints.down('sm')]: {
      width: 500,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  textContainer: {
    marginTop: 40,
    marginBottom: 16,
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  images: {
    cursor: 'pointer',
    borderRadius: 8,
    [theme.breakpoints.up('lg')]: {
      width: 176,
      height: 176,
    },
    [theme.breakpoints.down('md')]: {
      width: 144,
      height: 144,
    },
    [theme.breakpoints.down('sm')]: {
      width: 109,
      height: 109,
    },
    [theme.breakpoints.down('xs')]: {
      width: 93,
      height: 93,
    },
  },
}));