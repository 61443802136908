import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Container,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core/';
import PostMediumCard from './PostMediumCard'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: '#1A1A1A'
  },
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      minWidth: '960px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  img: {
    height: '500px'
  }
}));

export default function PostModal(props) {
  const classes = useStyles();
  const { open, handleClose, postData, postsAll, setPosts } = props;

  // useEffect(() => {
  //   console.log("Post Modal: ", postData)
  // }, [postData]);

  return (
    <Dialog PaperProps={{
      style: {
        backgroundColor: 'black',
        boxShadow: 'none',
        height: '100%'
      },
    }} fullScreen open={open} onClose={handleClose} style={{ backgroundColor: 'black' }}>
      <AppBar className={classes.appBar} elevation={0} color='transparent'>
        <Toolbar>
          <IconButton edge='end' onClick={handleClose} aria-label='close' style={{ color: 'white' }}>
            <ArrowBackIosIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {postData ?
        <Container maxWidth='md' className={classes.container}>
          <PostMediumCard post={postData} postsAll={postsAll} setPosts={setPosts} />
        </Container>
        : <div></div>
      }
    </Dialog>
  );
}