import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';
import algoliaClient from './utils/algolia/algoliaClient';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { connect } from 'react-redux';
import {
  changeLoginStatus,
} from './redux/actions';
import './styles/App.css';

import AdminPage from './pages/AdminPage'
import SignIn from './pages/SignIn';
import Signup from './pages/SignUp/SignUp';
import SignInLoading from './pages/SignIn/SignInLoading';
import Profile from './pages/Profile/Profile';
import ProfileEdit from './pages/Profile/ProfileEdit';
import Product from './pages/Product';
import GeneralProduct from './pages/GeneralProduct';
import UpcomingProduct from './pages/UpcomingProduct';
import AllUpcomingProducts from './pages/UpcomingProduct/AllUpcomingProducts';
import AllListedProducts from './pages/Product/AllListedProducts';
import AllGeneralProducts from './pages/GeneralProduct/AllGeneralProducts';
import Feed from './pages/Feed/Feed';
import About from './pages/About';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home';
import TopAppBar from './components/TopBar/TopAppBar';
import BottomBar from './components/BottomBar/BottomBar';
import DownloadAppModal from './components/Modals/DownloadAppModal';
import FinishSignUpModal from './components/Modals/FinishSignUpModal';

function App(props) {
  const {
    loginStatus,
    userGroup,
  } = props;
  const classes = useStyles()

  return (
    <div style={{ height: '100%', flexGrow: 1 }}>
      <InstantSearch
        indexName={'Users_Shoes'}         //PRODUCTION:
        // indexName={"Users_Shoes_DEV"}  //DEVELOPMENT
        searchClient={algoliaClient}
      >
        <Configure hitsPerPage={3} />
        <TopAppBar />
      </InstantSearch>

      {/* creates margin for top bar */}
      <div className={classes.toolbar}></div>

      <Switch>
        <Route exact path='/loading'>
          <SignInLoading />
        </Route>
        <Route exact path='/signup'>
          {!loginStatus ? <Signup /> : <Redirect to='/loading' />}
        </Route>
        <Route exact path='/signin'>
          {!loginStatus ? <SignIn /> : <Redirect to='/loading' />}
        </Route>
        <Route exact path='/feed'>
          <Feed />
        </Route>
        <Route exact path='/product/all'>
          <AllListedProducts />
        </Route>
        <Route exact path='/product/:productID'>
          <Product />
          <BottomBar />
        </Route>
        <Route exact path='/general/all'>
          <AllGeneralProducts />
        </Route>
        <Route exact path='/general/:productID'>
          <GeneralProduct />
          <BottomBar />
        </Route>
        <Route exact path='/upcoming/all'>
          <AllUpcomingProducts />
        </Route>
        <Route exact path='/upcoming/:productID'>
          <UpcomingProduct />
          <BottomBar />
        </Route>
        <Route exact path='/profile/:nickname'
          render={(props) => (
            <React.Fragment>
              <Profile key={props.match.params.nickname} {...props} />
              <BottomBar />
            </React.Fragment>
          )}>
        </Route>
        <Route exact path='/account/edit'>
          {loginStatus ? <ProfileEdit /> : <Redirect to='/loading' />}
        </Route>
        <Route exact path='/about'>
          <About />
        </Route>
        <Route exact path='/contact'>
          <Contact />
        </Route>
        <Route exact path='/admin'>
          {userGroup === 'admin' ? <AdminPage /> : <Redirect to='/loading' />}
        </Route>
        <Route exact path='/'>
          <Home />
          <BottomBar />
        </Route>
        <Route path='/'>
          <Redirect to='/' />
        </Route>
      </Switch>
      <DownloadAppModal />
      <FinishSignUpModal />
    </div>
  )
}

const mapStateToProps = state => {
  const { loginStatus, userProfile, userGroup } = state.reducer;
  return { loginStatus, userProfile, userGroup };
};

const mapDispatchToProps = dispatch => {
  return {
    changeLogin: loginStatus => dispatch(changeLoginStatus(loginStatus)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);


const useStyles = makeStyles(theme => ({
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      height: theme.appbarHeight.smDown,
    },
    [theme.breakpoints.up('md')]: {
      height: theme.appbarHeight.mdUp,
    },
  }
}));