import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: 'black',
    color: 'white',
    padding: '2em 0',
  },
  section: {
    margin: '2em 0',
  },
  toolbar: theme.mixins.toolbar,
}));