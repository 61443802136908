import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'right',
  },
  clickable: {
    cursor: 'pointer',
    color: 'black',
  },
  loginButton: {
    textTransform: 'none',
    height: 34,
    padding: 8,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1)',
  },
  signUpButton: {
    textTransform: 'none',
    height: 34,
    padding: 8,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'black',
  },
  loginText: {
    fontWeight: 'bold',
  },
  signUpText: {
    fontWeight: 'bold',
    color: 'white',
  },
}));