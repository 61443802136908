import React, { useState } from 'react';
import { useStyles } from './AdminPage.style';
import {
  Container,
  Grid,
  Tabs,
  Tab
} from '@material-ui/core';

import AdminOrders from '../../components/Admin/AdminOrders';
import AdminGeneralOrders from '../../components/Admin/AdminGeneralOrders';
import AdminCanceledOrders from '../../components/Admin/AdminCanceledOrders';
import AdminNotification from '../../components/Admin/AdminNotification';
import AdminPostUploader from '../../components/Admin/AdminPostUploader';
import AdminRaffleUploader from '../../components/Admin/AdminRaffleUploader';

export default function AdminPage() {
  const classes = useStyles();
  const [task, setTask] = useState(0);

  const handleChange = (event, newValue) => {
    setTask(newValue);
  };

  return (
    <div className={classes.root}>
      <Container >
        <Tabs
          value={task}
          indicatorColor='primary'
          textColor='primary'
          onChange={handleChange}
        >
          <Tab label='Listing Orders' />
          <Tab label='General Orders' />
          <Tab label='Canceled Orders' />
          <Tab label='Notification' />
          <Tab label='Post' />
          <Tab label='Raffle' />
        </Tabs>
        <Grid item xs={12}>
          {task === 0 ? (
            <AdminOrders/>
          ) : task === 1 ? (
            <AdminGeneralOrders/>
          ) : task === 2 ? (
            <AdminCanceledOrders/>
          ) : task === 3 ? (
            <AdminNotification />
          ) : task === 4 ? (
            <AdminPostUploader />
          ) : task === 5 ? (
            <AdminRaffleUploader />
          ) : null}
        </Grid>
      </Container>
    </div>
  );
}
