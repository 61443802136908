import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: 24,
    margin: '40px 4%',
    minHeight: 800,
    [theme.breakpoints.down('sm')]: {
      margin: '16px'
    },
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 40,
  },
  container: {
    width: '100%',
    maxWidth: '1142px',
  },
  card: {
    maxWidth: 272,
    display:'flex',
    flexDirection: 'column',
    marginBottom: 24,
  },
  cardTitle: {
      color: '#333333',
      height: 36,
      WebkitLineClamp: 2,
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
  },
  cardImage: {
    width: '100%',
    aspectRatio: 1,
    objectFit:'cover',
    borderRadius: 8,
    marginBottom: 8,
    cursor:'pointer',
  },
}));